import React, {useEffect, useState} from 'react'
import EmailService from "../../core/EmailService";
function FormContact() {
    const [inputs, setInputs] = useState({});
    const [respone, setRespone] = useState('');
    const [pending, setPending] = useState(false);
    const to_email = 'info@solv.com.gh';

    let requestState = (response) => {
        setRespone(response);
    };

    useEffect(() => {
        if(respone){
            setPending(false)
        }
    }, [respone, setPending])


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const message = inputs.message + `<br><br>
            <span style="color:#0F4C8A"><b>Sender Details</b></span><br>
            <span style="color:#FF7A00">Name:</span> <span style="color:#0F4C8A">${inputs.fullName}</span><br>
            <span style="color:#FF7A00">Email:</span> <span style="color:#0F4C8A">${inputs.emailAdd}</span><br><br>
            <img src="https://d1gruoz2d8pn1p.cloudfront.net/logo-idle.png" alt="solv logo" width="50"><br><br>
            <span style="background:#0F4C8A; padding:15px; border-radius:5px; color: #fff;">Sent from 
                <span style="background:#FF7A00; padding:8px; border-radius:5px; margin-left:10px; color: black">Solv's Website</span>
            </span>`
        if(
            inputs.fullName === '' || inputs.emailAdd === '' || inputs.message === '' ||
            inputs.fullName === undefined || inputs.emailAdd === undefined || inputs.message === undefined
        ) {
            alert("All fields are required!!!")
        } else {
            setPending(true)
            EmailService({name: inputs.fullName,  message: message, from_email: inputs.emailAdd, to_email: to_email, subject: `Contact Us: Enquiries from ${inputs.fullName}`, attachment: null}, requestState={requestState} )
        }
    }
  return (
    <div className={"form-for-contact"}>
      <form onSubmit={handleSubmit} className="contact-form">
          <input type="text"
                 value={inputs.fullName || ""}
                 name="fullName"
                 onChange={handleChange}
                 placeholder="Name" required="required"/>
          <input type="email"
                 value={inputs.emailAdd || ""}
                 name="emailAdd"
                 onChange={handleChange}
                 placeholder="Email" required="required"/>
          <textarea
              value={inputs.message || ""}
              name="message"
              onChange={handleChange}
              placeholder="Please type your message here..." rows="10" cols="50" required="required" maxLength="700"></textarea>
          <button type="submit" disabled={pending} >{ pending? "Sending..." : "Send message" }</button>
      </form>
    </div>
  )
}

export default FormContact
