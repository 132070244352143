import React from 'react'
import logo from '../assets/logo.svg'
import {Link, NavLink} from 'react-router-dom'
import ActiveLink from '../assets/active.svg'
import Playstore from '../assets/playstore.png'
let activeLinkStyle = {
    style: ({isActive}) => ({
        color: isActive ? '#FF7A00' : '#0F4C8A',
        background: isActive ? `url('${ActiveLink}') no-repeat bottom` : 'none',
        backgroundSize: isActive ? 'contain' : '',
        minWidth: isActive ? '100px' : ''
    })
}
function Nav() {
  return (
    <>
    <nav>
        <input type={ "checkbox" } id= { "check" } />
        <label htmlFor="check" className="checkbtn"><i className="fa fa-bars"></i></label>
        <label className="logo"><label className="logo"><Link to="/"><img loading='lazy' src= { logo } alt="logo"/></Link></label></label>
        <img className="playstore" src={Playstore} alt='playstore' />
            <ul>
                <li><NavLink to="/about" style={activeLinkStyle.style}>About Us</NavLink></li>
                <li><NavLink to="/finance" style={activeLinkStyle.style}>Financial Services</NavLink></li>
                <li><NavLink to="/business" style={activeLinkStyle.style}>Business Services</NavLink></li>
                <li><NavLink to="/careers" style={activeLinkStyle.style}>Careers</NavLink></li>
                <li><NavLink to="/contact-us" style={activeLinkStyle.style}>Contact Us</NavLink></li>
                {/* <li><NavLink to="/contact-us"><img className="playstore" src={Playstore} alt='playstore' /></NavLink></li> */}
            </ul>
    </nav> 
    {/* <label className="logo" style={{float: 'right', maxWidth: '100%'}} ><img src={Playstore} alt='playstore' /></label> */}
    </>
  )
}

export default Nav