import React from 'react';
import './_CustomerCard.css'
import Box from "@mui/material/Box";
import {Rating} from "@mui/material";
import {StarIcon} from "primereact/icons/star";
import DialogBox from "./DialogBox";

const cardWrapperStyle = (url) => {
    return  {
        background: `url(${url}) no-repeat`,
        backgroundSize: "cover",
    }
};
const CustomerCard = (props) => {
    return (
        <div>
            <div className="card-wrap" style={cardWrapperStyle(props.prop.bgImg)} key={props.prop.id}>
                <div className="card-detail-wrap">
                    <img loading='lazy' src={props.prop.pic} alt="user-p" className="card-img"/>
                    <div className="card-detail">
                        <span className={"c-name"}>{props.prop.name}</span>
                        <span className={"c-position"} >{props.prop.position}</span>
                        <span className={"c-details"} >{props.prop.detail.substring(0, 50) + "..."}</span>
                        <DialogBox data = {{
                            fullDetails: props.prop.detail,
                            picture: props.prop.pic,
                            name: props.prop.name,
                            position: props.prop.position,
                            toggle: true,
                            btnClass: "btn-launch",
                            btnValue: "Read more"
                        }}/>
                        <span><TextRating /></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

function TextRating() {
    return (
        <Box sx={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "10px", fontSize: "5px" }}>
            <Rating
                name="text-feedback"
                value={3.5}
                readOnly
                precision={0.5}
                size={'small'}
                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
        </Box>
    );
}

export default CustomerCard;