import React from 'react'
import Nav from '../components/Nav'
import Foot from '../components/Foot'
import LeftShape from "../assets/about/left-shap.svg";
import RightShape from "../assets/about/right-shape.svg";
import ContactImg from '../assets/contact-us/contact-img.png'
import FormContact from "../components/contact/FormContact";
import CardContact from "../components/contact/CardContact";
import {ScrollToTop} from "../core/ScrollToTop";

function ContactUs() {

  return (
    <div>
        <Nav />
        <ScrollToTop />
        <section className='one'>
            <div className="section-1-wrap-any">
                <span className="title">Our doors remain open…</span>
                <span className="details-fade">
                Come in for a discussion on how our financing and business support solutions can simplify the growth of your business
                </span>
            </div>
        </section>
        <section className="two-addition">
            <img loading='lazy' id="first-left" src={LeftShape} alt="first-left" />
            <img loading='lazy' id="first-right-extra" src={RightShape} alt="first-right" />
            <div className="section-2-wrap-x-">
                <div className="col-description-">
                    <span className="sub-title">Headquarters:</span>
                    <span className="details-fade"> 9th Floor, 87 Independence Avenue, Accra</span>
                    <span><i className="fa fa-phone cont"></i> <a href="tel: +233503614074">+233 (0) 50 361 4074</a></span>
                    <span><i className="fa fa-envelope cont"></i>  <a href="mailto:info@solv.com.gh">info@solv.com.gh</a></span>
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d992.7408228555504!2d-0.19155087262781237!3d5.572445785141432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9b987a8194f5%3A0xe370d574fca0f335!2sGoogle%20Standard%20Chartered%20Office%20Tower!5e0!3m2!1sen!2sgh!4v1692353337198!5m2!1sen!2sgh"
                        className="map-iframe" loading="lazy" title={"SOLV"}></iframe>
            </div>
            <div className="section-2-wrap-x-">
                <div className="col-checks-">
                    <img src={ContactImg} alt="contact-img" loading={"lazy"}/>
                </div>
                <div className="col-description--">
                    <span className="details-fade">CONTACT US</span>
                    <span className="sub-title">Get in touch today…</span>
                    <span className="details-fade">With your questions, comments, and feedback</span>
                    <FormContact />
                </div>
            </div>
        </section>
        <section className="one-extra">
            <CardContact />
        </section>
        <Foot />
    </div>
  )
}

export default ContactUs