import React from 'react'
import Nav from '../components/Nav'
import NewsTemplate from "../components/NewsTemplate";
import {ScrollToTop} from "../core/ScrollToTop";

function News() {
  return (
    <div style={{width:"100%"}}>
        <Nav />
        <ScrollToTop />
        {/*<h2 style={{textAlign: "center", marginBlockStart: "150px"}}>No News Available</h2>*/}
        {/* <iframe width="100%" height="60" style={{position:"fixed", borderTop: "solid #FF6200", borderTopWidth: "2px" }} src="https://rss.app/embed/v1/ticker/_qTBBCug2GxOvJUx4" frameborder="0" title='ticker'></iframe>
        <br />
        <br />
        <br />
        // <iframe width="100%" height="1670" src="https://rss.app/embed/v1/wall/_EQPH3V59v9xkyRKR" frameborder="0" title='solv'></iframe> */}
        {/* <Foot /> */}
        <NewsTemplate />
    </div>
  )
}

export default News