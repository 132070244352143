import './App.css';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home'
import About from './pages/About'
import Finance from './pages/Finance'
import Business from './pages/Business'
import ContactUs from './pages/ContactUs'
import Careers from './pages/Careers'
import ViewJob from "./pages/ViewJob";
import News from './pages/News';
import PrivacyPolicy from './components/privacyPolicy';
import TermsOfService from './components/termsOfService';
import { fetchJobs } from './redux/jobsThunk';

function App() {  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchJobs());
  }, [dispatch]);

    return (
      <BrowserRouter>
        <Routes>
            <Route index element={<Home />} />
            <Route path='about' element={<About />} />
            <Route path='finance' element={<Finance />} />
            <Route path='business' element={<Business />} />
            <Route path='careers' element={<Careers />} />
            <Route path='contact-us' element={<ContactUs />} />
            <Route path='careers/view-job/:id' element={<ViewJob />} />
            <Route path='news' element={<News />} />
            <Route path = "privacy-policy" element={<PrivacyPolicy/>}/>
            <Route path = "terms-of-service" element={<TermsOfService/>}/>
            <Route path='*' element={<div> <h1>Page not found!</h1> </div>} />
        </Routes>
      </BrowserRouter>
    
  );
}

export default App;
