import React , {useState, useEffect} from 'react';

import FILE from "../assets/news/newsList.xlsx";
import {read, utils} from "xlsx";
const style = {
    metadata : {
        display: "flex",
        justifyContent: "space-between",
        fontSize: "12px",
    }
}

function NewsTemplate() {
    const [data, setData] = useState([]);

    useEffect(()=> {(
        async()=> {
            const url = FILE;
            const file = await (await fetch(url)).arrayBuffer();
            const workbook = read(file);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            setData(utils.sheet_to_json(worksheet));
            
        })();
    }, []);

    const sortedData = data.sort((a,b)=>{
        return new Date(b.date) - new Date(a.date)}
    );
    return (
        <div>
            <div className="view-job-wrap">
                <br />
                <div className="scroll-text">
                    <h3 className="header">SOLV</h3>
                    <div className="mar">
                        <h3>SOLV Ghana in the News</h3>
                    </div>
                </div>
                <div className="sub-headings for-news">
                    {sortedData.map((item, index) => (
                            <div key={index} className="sides-wrap">
                                <img src={item.imgLink} alt="news" />
                                <div className="sides news-side">
                                    <a target="_blank" rel="noreferrer" href={item.newsLink} style={{ color: "blue" }}><h3>{item.headline}</h3></a>
                                    <span className="sides-details">{item.details}</span>
                                    <div style={style.metadata}>
                                        <span>Source: {item.source}</span>
                                        <span>{item.date}</span>
                                    </div>
                                </div>                                
                            </div>
                    ))}
                    
                </div>
            </div>
        </div>
    )
}

export default NewsTemplate;