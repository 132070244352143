import c1a from "../assets/c-1a.png";
import c1 from "../assets/c-1.png";
import c2a from "../assets/c-2a.png";
import c2 from "../assets/c-2.png";
import c3a from "../assets/c-3a.png";
import c3 from "../assets/c-3.png";
import c4a from "../assets/c-4a.png";
import c4 from "../assets/c-4.png";
import c5a from "../assets/c5a.png";
import c5 from "../assets/c-5.png";

export const CustomerCardService = {
    getCustomersData() {
        return [
            { id: 1, picture: c1a, name: 'Patrick Asiamah', position: 'Synlab Ghana - Master Anchor', bgImg: c1,
                fullDetails: `I am a representative from Synlab Ghana, formerly Medlab. We are a respected medical diagnostic center offering essential laboratory and imaging services. We cater primarily to healthcare institutions that outsource diagnostic needs, with payment being made to us on a monthly basis. However, financial challenges among clients have led to service interruptions, posing serious risks, even costing lives due to delayed diagnoses.
                \nOur collaboration with SOLV has emerged as a crucial solution. By providing financial aid to our clients, SOLV ensures uninterrupted access to Synlab's services. This partnership becomes a lifeline, preventing critical treatment delays and preserving lives. The support not only upholds Synlab's commitment to healthcare excellence but also bolsters trust among clients and patients.\n
                The joint effort exemplifies a profound impact on healthcare, transcending financial aid to become a guardian of life. It guarantees that diagnostic services remain available when most needed, forging a more secure and healthier future for Synlab Ghana, its clients, and the patients they serve. This partnership reflects the interconnectedness of timely diagnostics and the preservation of human life, highlighting the critical role that financial support can play in sustaining healthcare services.`
            },
            { id: 2, picture: c2a, name: 'Tina Ofori', position: 'Food & Beverages - Dealer', bgImg: c2,
                fullDetails: `Hello, I am Tina and I have been working in the soft drink and beverage industry for some time now and my journey hasn't been without challenges. One of the biggest hurdles was maintaining a constant supply of popular and in-demand beverages. There were times when I had to turn down bulk orders from retailers because I couldn't afford to purchase the necessary inventory upfront.
                \nGetting Onboarded with SOLV allows me stock up on a wider variety of beverages, including the latest trending flavors. This allows me fulfill larger orders from my retailers and also gives me the opportunity to offer more choices to my customers,subsequently attracting even more clients to my store.
                \nI am also able take advantage of peak periods, especially during festive seasons. This allows me maintain optimum inventory levels and also take advantage of forecasted price increments. I am also able to take advantage of discounts that come along with bulk purchases, of which the benefits outweigh the cost of capital.`
            },
            { id: 3, picture: c3a, name: 'Joseph Odame', position: 'Global Poly Ghana Ltd - Master Anchor', bgImg: c3,
                fullDetails: `Poly production is a hugely capital intensive venture. Global Poly Ghana is a manufacturing firm, dealing with the production of rubber films mainly for water sachet producers. Before we are able to satisfy any demand, we first and foremost have to import our raw material for production and upon import, we have just about three months to clear and pay for the goods. \n\nThis all takes place way before we receive the first order. High exchange rates, together with import duties both contribute to us having huge cash outflows way before production starts. Coupling such costs with credit sales affects our rate of production
                \nWith SOLV funding the purchases of our retailers, we receive payment prior to the goods being released. This enables us to effectively manage our accounts receivables and release currently tied up funds in credit sales, characterised by a reduction in our receivables. Our collaboration with SOLV, we believe will extend our reach, in our quest to offer a helping hand to our retailers in setting up their own venture and offering employment to the Ghanaian youth.`
            },
            { id: 4, picture: c4a, name: 'Jennifer', position: 'Zaara Yawaany Ent. (Fanmilk) - Anchor ', bgImg: c4,
                fullDetails: `As an anchor (wholesaler), I grappled with the constraints of my dealers' (retailers) working capital, as it led to frequent, smaller orders from vendors. This resulted in increased costs due to multiple deliveries and strained relationships with vendors. The expense was absorbed by us as wholesalers, impacting our efficiency and profitability, while dealers remained constrained by their working capital.
                \nFollowing our onboarding on the SOLV marketplace, the dynamics shifted drastically. Now, I can group orders into bulk purchases, eradicating the need for multiple deliveries. This transformation reduces costs for both parties and fosters smoother operations. \n\nWhile customers are expected to bear these costs, I, as a wholesaler, have assumed the responsibility for coordinating with dealers. This not only strengthens our dealer relationships but also optimizes our resources and improves overall supply chain efficiency.`
            },
            { id: 5, picture: c5a, name: 'Atta Yogot', position: 'Fanmilk - Dealer', bgImg: c5,
                fullDetails: `I want to take a moment to express my gratitude to all behind SOLV. As a dealer of fanmilk products, securing financial assistance from financial institutions can be very difficult. From high interest rates to demands for collaterals can be heartbreaking. Being onboarded with SOLV, there is no need for collateral. All that is required as a dealer is a business registration document, the Ghana Card and sales records.
                \nBeing onboarded on the SOLV platform guarantees me an absolute lifeline of funding as and when I need it. SOLV's seamless loan application process, together with their transparent terms and conditions, clearly shows their genuine commitment to assisting small businesses like mine.
                \nIn conclusion, partnering with SOLV has also helps cut overhead costs as it has significantly reduced the number of purchases being made periodically. With SOLV's prorated interest rate, I stand to pay interests as low as 1%.`
            }
        ]
    },

    getCustomers() {
        return Promise.resolve(this.getCustomersData());
    },
};

