import React, {useEffect, useState} from 'react';
import './_CustomerCardSlider.css';
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import CustomerCard from "../CustomerCard";
import {CustomerCardService} from "../../core/CustomerCardService";
function CustomerCardSlider() {
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        CustomerCardService.getCustomers().then((data) => setCustomers(data));
    }, []);

    return (
        <>
            <Swiper slidesPerView={1} spaceBetween={5}
                    pagination={{ clickable: true }} loop={true}
                    autoplay={{delay: 3000, disableOnInteraction: false}}
                    navigation={true} breakpoints = {{
                       640: { slidesPerView: 2, spaceBetween: 5, },
                       768: { slidesPerView: 3, spaceBetween: 20, },
                       1024: { slidesPerView: 4, spaceBetween: 30, }, }}
                    modules={[ Pagination, Navigation, Autoplay ]} className="mySwiper">

                    { customers.map((data, index) => { return(
                    <SwiperSlide key={index}>
                        <CustomerCard prop = {{ id: data.id, pic: data.picture, name: data.name, position: data.position, bgImg: data.bgImg, detail: data.fullDetails }} />
                    </SwiperSlide>
                            )}
                        )
                    }
            </Swiper>
        </>
    );
}

export default CustomerCardSlider;