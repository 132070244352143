
import React, { useState } from "react";
import { Dialog } from 'primereact/dialog';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import DialogBg from '../assets/dialog-bg.svg'
import ApplyForm from "./apply/ApplyForm";

const styles = {
    width: '50vw',
    padding: '15px 15px 30px 30px',
    backgroundColor: 'white',
    backgroundImage: `url(${DialogBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    textAlign: 'justify',
    wordSpacing: '-0.5px',
    hyphens: 'auto'
}

const stylesForm = {
    width: '50vw',
    padding: '15px 15px 30px 30px',
    backgroundColor: 'white'
}

function KeyValueComponent(keyValueString) {
    const formattedString = keyValueString.replace(/\n/g, '<br/>');
    return (
        <div dangerouslySetInnerHTML={{ __html: formattedString }} />
    );
}

function DialogBox(props) {
    const [visible, setVisible] = useState(false);


    return (
        <div className="card flex justify-content-center">
            { props.data.toggle ? ( <>
                <span className={props.data.btnClass} onClick={() => setVisible(true)}>{props.data.btnValue}</span>
            <Dialog visible={visible} onHide={() => setVisible(false)}
                    style={styles} breakpoints={{ '960px': '75vw', '641px': '100vw' }} draggable={false}
                    dismissableMask={true} contentClassName="dialog">
                {/*<p className="m-0">*/}
                    {KeyValueComponent(props.data.fullDetails)}
                {/*</p>*/}
                <div className={"img-details-wrap"}>
                    <img loading='lazy' src={props.data.picture} alt={"data-profile"} className="p-picture" />
                    <div className={"img-details"}>
                        <h4>{props.data.name}</h4>
                        <span>{props.data.position}</span>
                    </div>
                </div>
            </Dialog>
             </> ) : ( <>
                <button className={props.data.btnClass} onClick={() => setVisible(true)}>{props.data.btnValue}</button>
                <Dialog visible={visible} onHide={() => setVisible(false)}
                        style={stylesForm} breakpoints={{ '960px': '75vw', '641px': '100vw' }} draggable={false}
                        dismissableMask={false} contentClassName="dialog">
                   <ApplyForm detail={{ title: props.data.title }}/>
                </Dialog>
            </> )
            }

        </div>
    )
}

export default DialogBox
