import React from 'react'
import Nav from '../components/Nav'
import Foot from '../components/Foot'
import TopRight from "../assets/careers/top-right.svg";
import LeftShape from "../assets/about/left-shap.svg";
import RightShape from "../assets/about/right-shape.svg";
import I1 from "../assets/careers/img/1.png";
import I2 from "../assets/careers/img/2.png";
import I3 from "../assets/careers/img/3.png";
import I4 from "../assets/careers/img/4.png";
import I5 from "../assets/careers/img/5.png";
import I6 from "../assets/careers/img/6.png";
import I7 from "../assets/careers/img/7.png";
import I8 from "../assets/careers/img/8.png";
import I9 from "../assets/careers/img/9.png";
import I10 from "../assets/careers/img/10.png";
import I11 from "../assets/careers/img/11.png";
import JobComponent from "../components/JobComponent";
import {ScrollToTop} from "../core/ScrollToTop";

function Careers() {
    return (
        <div>
            <Nav/>
            <ScrollToTop />
            <img loading='lazy' id="top-right" src={TopRight} alt='top-right'/>
            <section className='one'>
                <div className='section-1-wrap-extra'>
                    <span className="title">Join us if you are passionate about transforming Ghana’s micro, small, and medium (MSME) business ecosystem</span>
                    <span className="details">
                    Our distinct positioning provides us, at SOLV, with an opportunity to impact MSMEs that support the West Africa sub-region's 200 million young and digital native population, as well as complement the aspiration of establishing Ghana as West Africa’s financial, industrial, and technology hub to boost local manufacturing and assembly, and help reduce the over USD10 billion annual import bill for key essentials such as food, beverages, personal care, electronic, and pharmaceutical products.
                    <br /><br />If you are passionate enough about building a Ghana-first digital Marketplace that simplifies the growth of MSMEs and impacts millions of lives, let’s talk!
                    </span>
                </div>
            </section>
            <section className="other-section">
                <img loading='lazy' id="first-left" src={LeftShape} alt="first-left"/>
                <img loading='lazy' id="first-right-extra" src={RightShape} alt="first-right"/>
                <div className="other-section-wrap">
                    <div className="title">Current Job Openings</div>
                    {/*<span className="all">All (2)</span>*/}
                    {/*<span className="all">Engineering (2)</span>*/}
                    <JobComponent />
                    <div className="section-5-wrap">
                        <span className="title">Life and culture at SOLV</span>
                        <span className="details">Life as a SOLVer is challenging, exhilarating and fun</span>
                        <div className="image-display">
                            <img loading='lazy' className='item' src={I1} alt="1"/>
                            <img loading='lazy' className='item' src={I2} alt="2"/>
                            <img loading='lazy' className='item' src={I3} alt="3"/>
                            <img loading='lazy' className='item' src={I4} alt="4"/>
                            <img loading='lazy' className='item' src={I5} alt="5"/>
                            <img loading='lazy' className='item' src={I6} alt="6"/>
                            <img loading='lazy' className='item' src={I7} alt="7"/>
                            <img loading='lazy' className='item' src={I8} alt="8"/>
                            <img loading='lazy' className='item' src={I9} alt="9"/>
                            <img loading='lazy' className='item' src={I10} alt="10"/>
                            <img loading='lazy' className='item' src={I11} alt="11"/>
                        </div>
                        <div className="disclaimer">
                            <span className="title-4">Disclaimer:</span>
                            <hr/>
                            <span className="details">
                            SOLV and its employees conform to strict code of conduct and governance standards. The Company has not authorized anyone, including employees, to offer employment in exchange for cash or such other consideration. In the event any person chooses to offer cash or such benefit to an employee of SOLV or any other person, affiliate or not, for receiving direct or indirect employment at SOLV, the Company will not be held liable for any losses or damage incurred. In the event any person approaches you with such offers, please let us know by sending an email to careers@solv.com.gh
                        </span>
                        </div>
                    </div>

                </div>
            </section>
            <Foot/>
        </div>
    )

}

export default Careers