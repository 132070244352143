import React, {useEffect, useState} from 'react';
import EmailService from "../../core/EmailService";


const style = {
    label: {
        textIndent: "10px",
        fontSize: "15px",
        marginTop: "15px",
        color: "grey"
    },
    wrap: {
       background: "#D7E2EFFF",
        display: "flex",
        width: "100%",
        justifyContent: "center",
        paddingInline: "10px",
        borderRadius: "10px"
    },
    form: {
        width: "100%",
        marginTop: "0",
        marginBlock: "20px"
    },

    file: {
        background: "transparent",
        textIndent: "0"
    },

    btn: {
        maxWidth: "100%",
        height: "40px",
        marginTop: "5px"
    }
}

function ApplyForm(props) {
    const [inputs, setInputs] = useState({});
    const [respone, setRespone] = useState('');
    const [pending, setPending] = useState(false);
    const to_email = 'careers@solv.com.gh';

    let requestState = (response) => {
        setRespone(response);
    };

    useEffect(() => {
        if(respone){
            setPending(false)
        }
    }, [respone, setPending])

    const handleChange = (event) => {
        let name = '';
        let value = ''
        let file = null;
        if(event.target.name === 'file'){
            file = event.target.files[0];
            setInputs(values => ({ ...values, "cv": file}));
        }else {
            name = event.target.name;
            value = event.target.value;
            setInputs(values => ({ ...values, [name]: value}));
        }
        console.table(inputs)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const message = `<span style="color:#FF7A00">Role Applied:</span> <span style="color:#0F4C8A">${props.detail.title}</span><br><br>
            <span style="color:#0F4C8A"><b>Applicant's Details</b></span><hr>
            <span style="color:#FF7A00">Name:</span> <span style="color:#0F4C8A">${inputs.fullName}</span><br>
            <span style="color:#FF7A00">Email:</span> <span style="color:#0F4C8A">${inputs.emailAdd}</span><br>
            <span style="color:#FF7A00">Phone:</span> <span style="color:#0F4C8A">0${inputs.phone}</span><br><br>
            <img src="https://d1gruoz2d8pn1p.cloudfront.net/logo-idle.png" alt="solv logo" width="50"><br><br>
            <span style="background:#FF7A00; padding:15px; border-radius:5px; color:black;">Applied on 
                <span style="background:#0F4C8A; padding:8px; border-radius:5px; margin-left:10px; color:#fff ">Solv's Website</span>
            </span>`

        if(
            inputs.fullName === '' || inputs.emailAdd === '' || inputs.phone === '' || inputs.cv === null ||
            inputs.fullName === undefined || inputs.emailAdd === undefined || inputs.phone === undefined || inputs.cv === undefined
        ) {
            alert("All fields are required!!!")
        } else {
            setPending(true)
           EmailService({name: inputs.fullName,  message: message, from_email: inputs.emailAdd, to_email: to_email, subject: `Job Application: from ${inputs.fullName}, Role: ${props.detail.title}`, attachment: inputs.cv}, requestState={requestState} )

        }
    }

    return (
        <div className={"form-for-contact"} style={style.wrap}>
            <form onSubmit={handleSubmit} className="contact-form" style={style.form}>
                <span><b>{props.detail.title}</b> Role</span>
                <label style={style.label}>Full Name</label>
                <input type="text"
                       value={inputs.fullName || ""}
                       name="fullName"
                       onChange={handleChange} placeholder={"Enter your full name"} required="required" />
                <label style={style.label}>Email Address</label>
                <input type="email"
                       value={inputs.emailAdd || ""}
                       name="emailAdd"
                       onChange={handleChange} placeholder={"Enter your email"} required="required" />
                <label style={style.label}>Mobile Number</label>
                <input type="number"
                       value={inputs.phone || ""}
                       name="phone"
                       onChange={handleChange} placeholder={"Enter your phone number"} required="required" />
                <label style={style.label}>Upload CV</label>
                <input type="file"
                       name="file"
                       onChange={handleChange} disabled={false}
                       style={style.file}
                       accept={"application/pdf"}/>
                <button type={"submit"} style={style.btn} disabled={pending} >{ pending? "Submitting..." : "Submit" }</button>
            </form>
        </div>
    );
}

export default ApplyForm
