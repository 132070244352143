import { createSlice } from '@reduxjs/toolkit';

export const jobsSlice = createSlice({
    name: "jobs list",
    initialState: {
        jobs: [],
    }, 
    reducers: {
        setJobs: (state, action) => {
            state.jobs = action.payload;
        }
    }
});

export const {setJobs} = jobsSlice.actions;
export default jobsSlice.reducer;
