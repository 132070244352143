import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import FILE from "../assets/job/job-template.ods";
import {read, utils} from "xlsx";
let style = {
    style: {
        background: "#FF7A00",
        borderColor: "#FF7A00",
        color: "#eef2f5"
    }
}
function JobComponent() {
    const [data, setData ] = useState([]);
    const [displayData, setDisplayData] = useState([])
    const [active, setActive] = useState('all')

    useEffect(() => { (async() =>{
        const url = FILE
        const file = await (await fetch(url)).arrayBuffer();
        const workbook = read(file);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        setData(utils.sheet_to_json(worksheet));
        if (displayData.length===0) { setDisplayData(data) }
    })(); }, [data, displayData]);

    const output = Object.entries(
        data.reduce((prev, { category }) => {
            prev[category] = prev[category] ? prev[category] + 1 : 1;
            return prev;
        }, {})
    ).map(([category, count]) => ({ category, count }))
     .sort((a, b) => b.count - a.count);

    const onFilterClicked = (event) =>{
        const jobCat = event.target.id
        const filteredData = data.filter(jobs => {
              return jobs.category === jobCat;
            })
        setActive(jobCat);
        jobCat === 'all' ? setDisplayData(data) : setDisplayData(filteredData)
    }

  return (
      <div className={"jobOpenings"}>
          <div className="jobOpenings-wrap">
          <span className="all" onClick={onFilterClicked} id={'all'} style={active === "all"? style.style: {style: ""}} >All {`(${data.length})`}</span>
          {output.map((jobs) => {
              return(
                <span id={jobs.category} className="all" onClick={onFilterClicked} style={active === jobs.category? style.style: {style: ""}} key={jobs.category}>{jobs.category} {`(${jobs.count})`}</span>
              )
          })}
          </div>
      <div className="job-cards-wrap">
        <div className='job-cards-inner-wrap'>
          {displayData.map(jobs => {
              return ( <div className="job-card" key={jobs.jobId}>
                        <div className="job-inner-card">
                        <span className="job-title">{jobs.jobTitle.slice(0,40)+"..."}</span>
                        <div className="date-desc">
                            <div className="date-desc-extra">
                              <span className="desc">{jobs.jobLocation} | {jobs.jobExperience} Years</span>
                              <span className="posted-time">Posted: { jobs.datePosted }</span>
                            </div>
                            <Link to={`/careers/view-job/${jobs.jobId}`} jobs={{...jobs}} className="btn">Job Details</Link>
                        </div>
                      </div>
                      </div> )
          })}
          </div>
      </div>
      </div>
  )
}

export default JobComponent
