
import AWS from 'aws-sdk';

const EmailService = (props, {requestState}) => {
    let propsData = {
        from_name: props.name,
        message: props.message,
        from_email: props.from_email,
        title: props.title,
        subject: props.subject,
        to_email: props.to_email,
        attachment: props.attachment
    }

    const SES_CONFIG = {
        accessKeyId : "AKIAYJ7Z5IG6NJF5SKGU",
        secretAccessKey: "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",
        region: "eu-west-1"
    }
    const AWS_SES = new AWS.SES(SES_CONFIG)
    const SendEmail = (to_email, from_name, message, from_email, title, subject, attachment) => {
        let params = {
            Destination: {
                ToAddresses: [to_email]
            },
            Message: {
                Body: {
                    Html: {
                        Charset: "UTF-8",
                        Data: message
                    }
                },
                Subject: {
                    Charset: "UTF-8",
                    Data: subject
                }
            },
            ReplyToAddresses: [to_email],
            Source: to_email
        }

        // let params = {
        //     Source: to_email,
        //     Destinations: [to_email],
        //     RawMessage: {
        //         Data: `${attachment}`
        //     },
        // }

        try{
            // const res = AWS_SES.sendRawEmail(params).promise()
            const res = AWS_SES.sendEmail(params).promise()
            res.then(function (data){
                requestState("success");
                alert("Successfully Submitted your application")
            }).catch( function (err) {
                alert("Oops! something went wrong. Try again")
                requestState("failed");
            })
        } catch (error){
            alert("Oops! something went wrong. Try again")
            requestState("failed");
        }
    }
    SendEmail(propsData.to_email, propsData.from_name, propsData.message, propsData.from_email, propsData.title, propsData.subject, propsData.attachment);
};

export default EmailService;
