import React from 'react'
import { Link } from 'react-router-dom'
import footerlogo from '../assets/footer-logo.svg'
import '../components/media/FooterRes.css'

function Foot() {
    let year = new Date().getFullYear();
  return (
    <>
        <div className="group-footer">
        <img className="footer-logo" src={ footerlogo } alt="footer-logo"/>
        <ul className={'contact-info'}>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/finance">Financial Services</Link></li>
            <li><Link to="/business">Business Services</Link></li>
            <li><Link to="/careers">Careers</Link></li>
        </ul>
        <ul className={'contact-info'}>
            <li style={{color: '#FF7A00'}}>Connect with us</li>
            <li className="contact-us-sub"><i className="fa fa-mobile"></i>&nbsp;&nbsp;<Link to={"tel:+233503614074"}>+233 (0) 50 361 4074</Link></li>
            <li className="contact-us-sub"><i className="fa-solid fa-square-envelope"></i>&nbsp;&nbsp;<Link to={"mailto:customercare@solv.com.gh"}>customercare@solv.com.gh</Link></li>
            <li className="contact-us-sub"><i className="fa-solid fa-location-dot"></i>&nbsp;&nbsp;<Link to="/contact-us">87 Independence Avenue, Accra</Link></li>
            <li className="contact-us-sub">
                <div className="socials">
                    <Link to="https://linkedin.com/company/solvghana"><i className="fa fa-linkedin-square"></i></Link>
                    <Link to="https://facebook.com/SOLVGhana"><i className="fa fa-facebook-square"></i></Link>
                    <Link to="https://x.com/SOLVGhana"><i className="fa fa-twitter-square"></i></Link>
                    <Link to="https://instagram.com/solvghana"><i className="fa fa-instagram"></i></Link>
                    <Link to="https://youtube.com/@SOLVGhana"><i className="fa fa-youtube"></i></Link>
                </div>
            </li>
        </ul>
        <ul className={'contact-info'}>
            <li><Link to="/terms-of-service">Terms of Services</Link></li>
            <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
            <li>Registered as Solvezy Technology Ghana Ltd.</li>
            <li>Copyright © {year} All Rights Reserved</li>
        </ul>
    </div>
    </>
  )
}

export default Foot