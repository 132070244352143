import React, { useEffect, useState } from 'react'
import '../components/_ViewJob.css'
import ViewJobBg from '../assets/view-job-bg.svg'
import Nav from "../components/Nav";
import {ScrollToTop} from "../core/ScrollToTop";
import {Link, useLocation, useParams} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { fetchJobs } from '../redux/jobsThunk';
// import DialogBox from "../components/DialogBox";

let style = {
    style: {
        backgroundImage: `url("${ViewJobBg}")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
    }
}
function ViewJob() {
    const { id } = useParams(); 
    const location = useLocation();

    const dispatch = useDispatch();
    const jobs = useSelector((state) => state.jobs.jobs);
    const [job, setJob] = useState(null);
  
    useEffect(() => {
      if (jobs.length === 0) {
        dispatch(fetchJobs());
      } else {
        const foundJob = jobs.find((job) => job.jobId === id);
        setJob(foundJob);
      }
    }, [dispatch, jobs, id]);
  
    useEffect(() => {
      if (jobs.length > 0) {
        const foundJob = jobs.find((job) => job.jobId === id);
        setJob(foundJob);
      }
    }, [jobs, id]);

    const jobObj = (jobs) ? job : location.state
    const resp = (jobObj) ? jobObj.jobResponsibilities.split(";") : [];
    const qual = (jobObj) ? jobObj.jobQualifications.split(";") : []
 

  return (
      jobObj? (
    <div>
        <Nav />
        <ScrollToTop />
        <div className="view-job-wrap">
            <Link to={"/careers"} className={"apply go-back"}
                  style={{backgroundColor: "#FF7A00", marginTop: "10px", paddingInline: "10px"}}><i
                className="fa fa-arrow-left" style={{marginLeft: "0", marginRight: "5px"}}></i>Go Back</Link>
            <div className="top-banner" style={style.style}>
                <div className="view-headings">
                    <h2 className="view-title">{jobObj.jobTitle}</h2>
                    <h5 className="view-loc">{jobObj.datePosted} | <span className="posted-time">Posted: {jobObj.datePosted}</span>
                    </h5>
                </div>
                {/*<DialogBox data = {{ btnClass: "apply", btnValue: "Appy", toggle: false, title: job.title }} />*/}
                {/*<h3>Send Application to <a href="mailto:careers@solv.com.gh">careers@solv.com.gh</a></h3>*/}
                <a href={jobObj.applyUrl} target="_blank" rel="noreferrer" style={{
                    backgroundColor: 'blue',
                    width: 'fit-content',
                    padding: '10px',
                    color: "white",
                    borderRadius: '5px'
                }}>Apply Here</a>
            </div>
            <div className="sub-headings">
                <div className="sides">
                    <h3 className="sides-title">Location</h3>
                    <h5 className="sides-details">{jobObj.jobLocation}</h5>
                </div>
                <div className="sides">
                    <h3 className="sides-title">Experience</h3>
                    <h5 className="sides-details">{jobObj.jobExperience} Years</h5>
                </div>
            </div>
            <div className="view-details">
                <div className="responsibilities">
                    <h3 className="sides-title">Job Summary</h3>
                    <p>{jobObj.jobSummary}</p>
                </div>
                <div className="responsibilities">
                    <h3 className="sides-title">Job Responsibilities</h3>
                    <ul>
                        {
                            resp.map((jobResponsibilities, index) => {
                                return (<li key={index}>{jobResponsibilities}</li>)
                            })
                        }
                    </ul>
                </div>
                <div className="qualifications">
                    <h3 className="sides-title">Qualifications</h3>
                    <ul>
                        {
                            qual.map((jobQualifications, index) => {
                                return (<li key={index}>{jobQualifications}</li>)
                            })
                        }
                    </ul>
                </div>
            </div>
            {/*<DialogBox data={{btnClass: "apply", btnValue: "Appy", toggle: false, title: job.title}}/>*/}
            {/*<h3>Send Application to <a href="mailto:careers@solv.com.gh">careers@solv.com.gh</a></h3>*/}
            <a href={jobObj['applyUrl']} target="_blank" rel="noreferrer" style={{
                backgroundColor: 'blue',
                width: 'fit-content',
                padding: '10px',
                color: "white",
                borderRadius: '5px'
            }}>Apply Here</a>
        </div>
    </div>) :
          (<>Job not found</>)
  )
}

export default ViewJob
