import React from 'react'
import './_t&cs-policy.css'
import Nav from './Nav'
import {ScrollToTop} from '../core/ScrollToTop'

function PrivacyPolicy() {
  return (
    <>
        <Nav/>
        <ScrollToTop />
        <div className="pt-container">        
            <div id="pt-header">
                <div id="pt-heading">
                    <span>PLATFORM PRIVACY POLICY</span>
                </div>            
            </div>
            <hr />
            <div className="pt-content-bg"></div>
            <div className="pt-content">
                <p>
                This privacy policy (“Policy”) will help you understand what
                information we collect, why we collect it, how we use it, including if
                and how we share the information with third parties, and the choices
                you have in relation to your information. This privacy policy
                (“Policy”) describes the manner in which SOLV GHANA, a business of
                Standard Chartered Group (“SOLV GHANA”) collects information/data from
                you, how we store, use, process and disclose such information, and the
                steps we take to protect such information.
                </p>
                <p>
                “We”, “our” and “us” refer to SOLV GHANA. “You” and “your” refer to
                the current and former visitors to the Website, User and/ or
                his/her/its Authorized Representative (defined below), directors or
                owners, as applicable.
                </p>
                <p>
                By accessing the Website (defined below) or using the SOLV GHANA
                Services (defined below), the User agrees that the User and the
                Authorized Representative have read and understood, and, agree to be
                bound by the Policy. This Policy is incorporated into and subject to
                the Terms of Service available at <a href="https://www.solv.com.gh" 
                target="_blank" rel="noreferrer">www.solv.com.gh</a>.
                By using the Website, you expressly consent to our use and disclosure
                of your Personal Information and Sensitive Personal Information in
                accordance with this Policy.
                </p>
                <p>
                Further, if you are accessing the SOLV GHANA Services through the
                Mobile Application (defined below), then while this Policy shall apply
                to such use, there may be privacy terms (such as the terms imposed by
                mobile application stores like Apple’s App Store (iOS), Android’s
                (Google) Play Store, Microsoft’s store, from time to time) which will
                govern the use of the Mobile Application.
                </p>
                <p>
                Further, this Policy applies to all current and former visitors to the
                Website. It is strongly recommended for you to return to this page
                periodically to review the most current version of the Policy.
                </p>
                <p>
                SOLV GHANA is committed to maintain the confidentiality, integrity,
                and security of all information of our Users. It is important to read
                the entire Policy along with our Terms of Service carefully as some of
                the provisions affect your legal rights and obligations.
                </p>
                <div>
                <ol>
                    <li><b> Definitions</b></li>
                    <p>
                    <b>Account</b> means the account created by the User on the
                    Website after successful completion of registration.
                    </p>
                    <p>
                    <b>Authorized Representative</b> means the employee or authorised
                    representative of the User who is specifically authorized to
                    register on the Website on behalf of the User to apply for the
                    SOLV GHANA Services.
                    </p>
                    <p>
                    <b>CRB</b> means a credit reference bureau that has been duly
                    licensed and granted a certificate of registration in the
                    respective jurisdiction in which it is incorporated.
                    </p>
                    <p>
                    <b>Credit Information</b> means collectively the information you
                    provide to us in the online application which pertains to your
                    financial background and such other information obtained from the
                    CRB.
                    </p>
                    <p>
                    <b>Partner</b> means third party partners of SOLV GHANA from whom
                    SOLV GHANA may obtain certain services in relation to the Website
                    including but not limited to the CRB’s, verification companies,
                    information repositories, data analytics providers, payment
                    gateways, etc. to provide the SOLV GHANA Services.
                    </p>
                    <p>
                    <b>Platform</b> means digital platform which currently is in Alpha
                    form and accessible to a closed set of users operated and
                    maintained by SOLV GHANA, the front-end version of which is
                    accessible through the Website and Mobile Application.
                    </p>
                    <p>
                    <b>Personal Data</b> is any information which can be used to
                    identify you or from which you are identifiable. This includes but
                    is not limited to your name, nationality, telephone number, bank
                    details, email address, your image, government-issued
                    identification numbers, biometric data, race, date of birth,
                    marital status, religion, employment information and financial
                    information.
                    </p>
                    <p>
                    <b>SOLV GHANA Services</b> means the service of providing the
                    Website and the Platform which facilitates (i) the supply of
                    financial services by third parties to Users, (ii) the provision
                    of business services by third parties to Users and (iii) such
                    other services that may be provided by SOLV GHANA on the Platform
                    from time to time.
                    </p>
                    <p>
                    <b>Special Personal Data (SPD)</b> means data pertaining to your
                    race, national ID information, religious beliefs, background
                    information (including financial and criminal records, where
                    legally permissible), health data, disability, marital status, and
                    biometric data, as applicable.
                    </p>
                    <p>
                    <b>Third Party Service Providers</b> means service providers
                    including banks, credit bureaus, service providers and logistics
                    providers.
                    </p>
                    <p>
                    <b>Usage Information</b> means information that we automatically
                    collect about how you access and interact with the SOLV GHANA
                    Services.
                    </p>
                    <p>
                    <b>User</b> means any individual/organisation (as the case maybe)
                    registered on the Website for using the SOLV GHANA Services.
                    </p>
                    <p>
                    <b>User Data</b> means any data submitted by the User or the
                    Authorized Representative on behalf of the User, to or in relation
                    to the SOLV GHANA Services.
                    </p>
                    <p>
                    <b>Website</b> means the <a href="https://www.solv.com.gh" target="_blank" rel="noreferrer">www.solv.com.gh</a>and/or the SOLV GHANA Mobile Application.
                    </p>
                    <p>
                    Any term that is capitalised herein and not defined in this Policy
                    shall have the meaning given to it in the Terms of Service
                    available at &nbsp;
                    <a href="https://www.solv.com.gh" target="_blank" rel="noreferrer">www.solv.com.gh</a
                    >
                    </p>
                    <li><b> Information We Collect</b></li>
                    <p>
                    2.1 User-Provided Information: This Policy applies to User Data
                    collected from you or our Partners, such as details pertaining to
                    your name, parentage, nationality, state/city of residence,
                    residential address, e-mail address, date of birth, gender,
                    contact number/mobile number, user IDs, passwords, net banking
                    credentials, bank account details, recent photograph, signature
                    image, income proof, Tax ID number, credit information report, and
                    other Know Your Customer (KYC) documents including identity and
                    address proof, driver license or other government issued identity
                    proof, which may be shared/ uploaded by you, as and when you avail
                    any SOLV GHANA Services, and to which SOLV GHANA may become privy
                    to.
                    </p>
                    <p>
                    2.2 Information We Automatically Collect: In addition to
                    information you provide to us, we automatically collect certain
                    information about how you access and interact with the SOLV GHANA
                    Services (“Usage Information”). This Usage Information is a key
                    part of how we improve your experience on our Website and Mobile
                    Application and provide you with more personalized services. We
                    may use various technologies to collect and store such Usage
                    Information in connection with the SOLV GHANA Services. These may
                    include things like cookies, browser web storage (e.g., HTML5),
                    web beacons and similar technologies. There are four types of
                    Usage Information we may collect:<br/>
                    <span>
                        a. Device Information. This is information about how you access
                        the SOLV GHANA Services, such as the operating system, hardware
                        model, application or browser type and version, and unique
                        identifiers associated with your device.<br />
                        b. Log Information. There are certain pieces of information we
                        automatically collect in system logs or similar files about how
                        you interact with SOLV GHANA Services, such as which features
                        you visit or click on. This Log Information may also include
                        things like your IP address, browser or device configuration,
                        date and time of access and cookie information.<br />
                        c. Location Information. We may use certain information like
                        your IP address or other Device Information or Log Information
                        to estimate your location (e.g., your city or state). We may
                        also request to use location-enabled services on your device
                        (which typically provide GPS or Wi-Fi access point details) to
                        enhance our services. We will only use these location-enabled
                        services on your device with your consent, though.
                    </span>
                    </p>
                    <p>
                    2.3 Third Party Web Beacons and Third Party Buttons: We may
                    display third-party content on the Website or Mobile Application,
                    including third-party advertising. Third-party content may use
                    cookies, web beacons, or other mechanisms for obtaining data in
                    connection with your viewing of the Third Party content on the
                    Website. Additionally, we may implement third party buttons (such
                    as “like” or “share” buttons, social media buttons, etc.) that may
                    function as web beacons even when you do not interact with the
                    button. Information collected through third-party web beacons and
                    buttons is collected directly by these third parties, not by SOLV
                    GHANA. Information collected by a third party in this manner is
                    subject to that third party’s own data collection, use, and
                    disclosure policies.
                    </p>
                    <p>
                    2.4 Information from Other Sources: Many of our features rely on
                    information about you that we collect from Partners and Third
                    Party Service Providers, such as information pertaining to you in
                    relation to tax reports, Credit Information, your corporate
                    information, etc. from the records maintained by the Ghana Revenue
                    Authority and other governmental authorities or our Partners.
                    </p>
                    <li><b> Express Consent</b></li>
                    <p>
                    3.1 By providing User Data as mentioned hereinabove, you expressly
                    consent to SOLV GHANA (including its marketing channels, Partners
                    and Third Party Service Providers) to contact you through SMS,
                    call, e-mail, push notifications, social media and/or other
                    channels that may be used for communication from time to time and
                    with follow-up communication in relation to the SOLV GHANA
                    Services, for imparting product knowledge, offering promotional
                    offers running on the Website, and various other offers offered by
                    the Third Party Service Providers or SOLV GHANA. If you do not
                    wish to receive the aforesaid communications from us or if you
                    want to withdraw any consent which you have previously given,
                    please contact the Data Protection Officer via the details stated
                    in this Policy.
                    </p>
                    <p>
                    3.2 The usage of the Website may also require you to provide
                    consent for keying in/ uploading your User Data (including but not
                    limited to user IDs and passwords), as may be necessary to process
                    your application through the Platform. Any User Data which
                    requires to be keyed in/ uploaded is required for enabling hassle
                    free, faster, and paperless (to the extent possible) processing of
                    applications for SOLV GHANA Services availed to you.
                    </p>
                    <p>
                    3.3 You hereby authorize and expressly provide consent for us to
                    share your User Data with third parties including but not limited
                    to Partners including CICs, and Third Party Service Providers.
                    </p>
                    <p>
                    3.4 SOLV GHANA reserves the right (and you expressly authorize
                    SOLV GHANA) to share or disclose your User Data when SOLV GHANA
                    determines, in its sole discretion, that the disclosure of such
                    information is necessary or appropriate under applicable laws.
                    </p>
                    <li><b>Credit Information Reports</b></li>
                    <p>
                    4.1 Your Credit Information will be procured from all authorised
                    local Credit Reference Bureaus (CRBs) by our Financial Institution
                    partners, and SOLV GHANA will have no control over the content or
                    accuracy of information provided in your Credit Information. Our
                    Financial Institution partners will receive this information from
                    a CRB once you provide authorization/ consent in a template
                    provided incorporated in the Terms of Service to appoint our
                    Financial Institution partners as your lawfully appointed
                    authorized agents/ representative for receiving your Credit
                    Information and provide your consent on the CRB’s portal as well
                    on your behalf.
                    </p>
                    <p>
                    4.2 By authorizing our Financial Institution partners to receive
                    your Credit Information report, you agree that the Financial
                    Institution partners and CRBs shall be entitled to rely on your
                    authorization and consent granted by you through SOLV GHANA.
                    </p>
                    <p>
                    4.3 SOLV GHANA’s Financial Institution partners may analyze and
                    use your Credit Information, in order to provide you with the
                    financial services you have applied for on the Website or Mobile
                    Application, and you consent to such access by Third Party Service
                    Providers of your Credit Information including your PI and SPI.
                    </p>
                    <p>
                    Any derivative outputs so developed independently by SOLV GHANA
                    may be shared with Third Party Service Providers to enable them to
                    provide the SOLV GHANA Services to you.
                    </p>
                    <li>
                    <b>How We Use the Information We Collect</b>
                    </li>
                    <p>
                    We use the User Data in a variety of ways in providing the SOLV
                    GHANA Service and operating our business, including the following:
                    </p>
                    <p>
                    5.1 To operate, maintain, enhance, and provide all features of the
                    SOLV GHANA Service, to provide services and information that you
                    request, to respond to comments and questions and otherwise to
                    provide support to Users, and to process and deliver entries and
                    rewards in connection with promotions that may be offered from
                    time to time on the Website.
                    </p>
                    <p>5.2 For training and quality monitoring.</p>
                    <p>
                    5.3 To enable our Financial Institution partners access your
                    Credit Information from the CRB on your behalf and create
                    independent derivative outputs from such Credit Information for
                    the provision of SOLV GHANA’s Services.
                    </p>
                    <p>
                    5.4 To understand and analyze the usage trends and preferences of
                    our Users, to improve the SOLV GHANA Services, and to develop new
                    products, services, features, and functionality.
                    </p>
                    <p>
                    5.5 To contact you for administrative purposes such as customer
                    service or to send communications, including updates on promotions
                    and events, relating to products and services offered by us and by
                    third parties and you hereby consent to receiving such
                    communications by agreeing to these terms.
                    </p>
                    <p>
                    5.6 We may use cookies and automatically collected information to:
                    </p>
                    <p style={{"marginLeft":"12px"}}>
                    (i) personalize the SOLV GHANA Service, such as remembering
                    information about you so that you will not have to re-enter it
                    during your visit or the next time you visit the Website or use
                    the Mobile Application;
                    </p>
                    <p style={{"marginLeft":"12px"}}>
                    (ii) provide customized advertisements, content, and information;
                    </p>
                    <p  style={{"marginLeft":"12px"}}>
                    (iii)monitor and analyze the effectiveness of the SOLV GHANA
                    Service and third-party marketing activities;
                    </p>
                    <p style={{"marginLeft":"12px"}}>
                    (iv) monitor aggregate site usage metrics such as total number of
                    Users, features used, and pages viewed; and
                    </p>
                    <p style={{"marginLeft":"12px"}}>
                    (v) track your interactions, submissions, status or other
                    activities on the Platform.
                    </p>
                    <p>
                    5.7 We will not retain information you provide for longer than is
                    required for the purposes for which the information may lawfully
                    be used or is otherwise required under any other law for the time
                    being in force.
                    </p>
                    <li>
                    <b>When We Disclose or Transfer your Information</b>
                    </li>
                    <p>
                    Except as described in this Policy, we will not disclose User Data
                    to third parties without your consent, and in the manner detailed
                    in this Policy. We may disclose information to third parties if
                    you consent to us doing so, as well as in the following
                    circumstances:
                    </p>
                    <p>
                    6.1 For availing services from Users, Third Party Service
                    Providers or Partners - We provide our Third Party Service
                    Providers your User Data to enable them to provide certain
                    services to you via the Platform, and to Partners in order to
                    obtain information from them, as described in this Policy. We may
                    share your SPI with our Partners and Third Party Service
                    Providers. We may also provide your User Data to other Users to
                    facilitate your transactions with such Users on the Platform.
                    </p>
                    <p>
                    6.3 When we collaborate with third parties to provide or improve
                    SOLV GHANA Services - We may work with other third parties to
                    provide website and application development, hosting, maintenance,
                    and other services for us. These third parties may have access to
                    or process information about you as part of providing those
                    services for us. Generally, we limit the information provided to
                    these service providers to that which is reasonably necessary for
                    them to perform their functions, and we require them to agree to
                    maintain the confidentiality of such information.
                    </p>
                    <p>
                    6.5 As part of a corporate change - User Data maybe disclosed and
                    otherwise transferred to an acquirer, successor, or assignee as
                    part of any merger, acquisition, debt financing, sale of assets,
                    or similar transaction, or in the event of any insolvency,
                    bankruptcy, or receivership in which information is transferred to
                    one or more third parties as one of our business assets.
                    </p>
                    <p>
                    6.6 Business or marketing purposes - We may make certain
                    aggregated, automatically-collected, or otherwise non-personal
                    information available to third parties for various purposes,
                    including   (i) compliance with various reporting obligations; (ii)
                    for business or marketing purposes; or (iii) to assist such
                    parties in understanding our Users’ interests, habits, and usage
                    patterns for certain programs, content, services, advertisements,
                    promotions, and/or functionality available through the SOLV GHANA
                    Service.
                    </p>
                    <p>
                    6.7 For legal reasons - We may disclose User Data if required to
                    do so by law or in the good-faith belief that such action is
                    necessary to comply with applicable laws, in response to a court
                    order, judicial or other government subpoena or warrant, or to
                    otherwise cooperate with law enforcement or other governmental
                    agencies.
                    </p>
                    <p>
                    6.8 With your consent - There may also be other limited contexts
                    in which we share specific types of User Data with your express
                    consent.
                    </p>
                    <li><b>Your Privacy Choices</b></li>
                    <p>
                    You may, of course, decline to share certain information with us,
                    in which case we may not be able to provide to you some of the
                    features and functionality of the Website, Mobile Application and
                    the Platform. You may update, correct, or delete your Account
                    information and preferences at any time by accessing your Account
                    preferences page on the Website. If you wish to delete, access, or
                    amend any User Data that you have shared with us, you may contact
                    us at &nbsp;
                    <a href="mailto:Support@solv.com.gh">Support@solv.com.gh</a>. If
                    you wish to withdraw consent that you have given to this Privacy
                    Policy or to the Terms of Service, you may contact us at &nbsp;
                    <a href="mailto:Support@solv.com.gh">Support@solv.com.gh</a>.
                    Please note that while any changes you make will be reflected in
                    active user databases within a reasonable period of time, we may
                    retain all information you submit for backups, archiving,
                    prevention of fraud and abuse, analytics, satisfaction of legal
                    obligations, or where we otherwise reasonably believe that we have
                    a legitimate reason to do so.
                    </p>
                    <p>
                    If you receive commercial emails from us, you may unsubscribe at
                    any time by following the instructions contained within the email.
                    You may also opt out from receiving commercial emails from us by
                    sending your request to us by email at &nbsp;
                    <a href="mailto:Support@solv.com.gh">Support@solv.com.gh</a>. We
                    may allow you to view and modify settings relating to the nature
                    and frequency of promotional communications that you receive from
                    us through the Account preferences page on the Website.
                    </p>
                    <p>
                    Please be aware that if you opt out of receiving commercial emails
                    from us or otherwise modify the nature or frequency of promotional
                    communications you receive from us, it may take up to ten business
                    days for us to process your request, and you may continue
                    receiving promotional communications from us during that period.
                    Additionally, even after you opt out from receiving commercial
                    messages from us, you will continue to receive administrative
                    messages from us regarding the SOLV GHANA Services you have
                    applied or opted for.
                    </p>
                    <p>
                    You may choose to opt out of SMS promotional services as well. To
                    opt out, send an SMS keyword with number to be defined. Once you
                    send the request, it may take up to 10 business days to process
                    your request. Further, upon a request to opt-out of SMS
                    communication, you will be able to opt out of only promotional
                    messages. You will continue to receive transaction related
                    messages.
                    </p>
                    <li><b>Data Security</b></li>
                    <p>
                    We use certain physical, managerial, and technical safeguards that
                    are designed to improve the integrity and security of information
                    that we collect and maintain as required by applicable law. We
                    have implemented up to date standard for all processes supporting
                    the development and delivery of SOLV GHANA services. Please be
                    aware that no security measures are perfect or impenetrable. We
                    cannot and do not guarantee that information about you will not be
                    accessed, viewed, disclosed, altered, or destroyed by breach of
                    any of our physical, technical, or managerial safeguards.
                    </p>
                    <li>
                    <b>Confidentiality of Login ID and Password</b>
                    </li>
                    <p>
                    You are responsible for maintaining the security of your login ID
                    and password for your Account and must not provide these
                    credentials to any third party. If you believe that they have been
                    stolen or been made known to others, you must contact us
                    immediately at &nbsp;
                    <a href="mailto:customercare@solv.com.gh"
                        >customercare@solv.com.gh</a
                    >. We are not responsible if someone else accesses your Account
                    through the login credentials they have obtained from you or
                    through a violation by you of this Policy or our Terms of Service.
                    </p>
                    <li>
                    <b>Changes and Updates to this Policy</b>
                    </li>
                    <p>
                    We may amend or update our Policy. Please revisit this page
                    periodically to stay aware of any changes to this Policy, which we
                    may update from time to time. We may provide you notice of
                    material amendments to this Policy, as appropriate, and update the
                    “Last Modified” date at the top of this Policy. Your continued use
                    of the SOLV GHANA Services confirms your acceptance of our Policy,
                    as amended. If you do not agree to our Policy, as amended, you
                    must stop using our Services.
                    </p>
                    <li><b>Data Protection Officer</b></li>
                    <p>
                    If you have any concerns or questions in relation to this Policy,
                    you may address them to our grievance officer whose name and
                    contact details are as follows:<br />
                        <span>Name: Rita Adane</span>
                        <span>Email: at &nbsp; <a href="mailto:compliance@solv.com.gh">compliance@solv.com.gh</a></span>
                    </p>
                </ol>
                </div>
            </div>            
        </div>
    </>
  )
}

export default PrivacyPolicy