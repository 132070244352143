import React from 'react'
import Nav from '../components/Nav'
import Foot from '../components/Foot'
import TopRight from '../assets/finance/finance-top-right.svg'
import LeftShape from '../assets/about/left-shap.svg'
import RightShape from '../assets/about/right-shape.svg'
import Tranzact from '../assets/finance/fi/tranzact.png'
import sc from '../assets/finance/fi/sc.png'
import gbc from '../assets/finance/fi/gbc.png'
import cbg from '../assets/finance/fi/cbg.png'
import dbg from '../assets/finance/fi/dbg.png'
import {ScrollToTop} from "../core/ScrollToTop";
import pdfFile from '../assets/SCF-flyer-English.pdf'

function Finance() {
  return (
    <div>
        <Nav />
        <ScrollToTop />
        <img id="top-right" src={TopRight} alt='top-right' />
          <section className='one'>
            <div className='section-1-wrap-extra'>
            <span className="title">Quick and easy access to finance</span>
            <span className="details">
            The SOLV Marketplace, backed by user-friendly robust technology and a vast network of participants, provides small businesses with a myriad of financing and support solutions that simplify and sustain their growth.
            </span>
            </div>
          </section>

          <section className='two'>
              <img id="first-left" src={LeftShape} alt="first-left" />
              <img id="first-right-extra" src={RightShape} alt="first-right" />
              <div className="section-2-wrap-x">
                <div className="col-description">
                  <span className="title">Supply Chain Finance</span>
                  <span className="details">
                      <p className="fs">Convenient Working Capital financing</p>
                      The SOLV Dealer finance solution is an Anchor-led invoice financing program that helps dealers and distributors of manufacturers and importers leverage their relationship with their suppliers (Anchors) to procure collateral free financing for shortfalls in their Working Capital, sourced at attractive rates from SOLV’s extensive network of Bank and non-Bank financial institutions. 
                      <br /><br />Our comprehensive tailor-made financing Programs, powered by a blockchain-enabled digital platform, simplifies the challenges related to management of peak season inventory financing.
                      </span>
                    <iframe className="video-img" src="https://www.youtube.com/embed/tTX2snxVgXY?si=xVSTZ-8ksC33oSoh" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
              <div className="col-checks">
                  <div className="bus-check">
                    <i className="fa fa-check"></i><span>Collateral free</span>
                  </div>
                  <div className="bus-check">
                    <i className="fa fa-check"></i><span>Competitive interest rates</span>
                  </div>
                  <div className="bus-check">
                    <i className="fa fa-check"></i><span>Assured and steady source of financing</span>
                  </div>
                  <div className="bus-check">
                    <i className="fa fa-check"></i><span>Improved liquidity enabling expanded inventory</span>
                  </div>
                  <div className="bus-check">
                    <i className="fa fa-check"></i><span>Additional finance available during peak season</span>
                  </div>
                <span className="download">Download brochure:
                        <a href={pdfFile} download="SOLV-GHANA-BROCHURE" target="_blank" rel="noreferrer">Download &nbsp;
                            <i className="fa-solid fa-download"></i></a>
                        {/*<select>*/}
                        {/*    <option value="">Select</option>*/}
                        {/*    <option value="en">English</option>*/}
                        {/*    <option value="fr">French</option>*/}
                        {/*</select>*/}
                    </span>
                </div>
              </div>
          </section>
          <section className='two-extra'>
            <span className="title">Our trusted FI partners</span>
            <span className="extra-details" style={{textAlign: "center"}}>
                <img src={sc} alt="sc" />
                <img src={Tranzact} alt="tranzact" />
                <img src={cbg} alt="cbg" width="150px"/>
                <img src={gbc} alt="gbc" width="130px"/>
                <img src={dbg} alt="dbg" />
            </span>
            <span className="extra-details" style={{textAlign: "center"}}>
            Our extensive network of Financial Institution (FI) partners enables us to connect Dealers on our Marketplace financing support that best addresses Working Capital challenges specific to their specific value chain.
            </span>
          </section>

        <Foot />
    </div>
  )
}

export default Finance