import React from 'react'
import CareersImg from '../../assets/contact-us/careers.svg'
import MediaImg from '../../assets/contact-us/media.svg'
import OthersImg from '../../assets/contact-us/others.svg'

const cardDetails = [
    { id: 1, img: CareersImg, title: "careers", details: "For job opportunities, please contact", email: "careers@solv.com.gh" },
    { id: 2, img: MediaImg, title: "media relations", details: "If you are a member of the media or trade press, please contact", email: "info@solv.com.gh" },
    { id: 3, img: OthersImg, title: "others", details: "For all other enquiries, please contact", email: "compliance@solv.com.gh" }
]

function CardContact() {
    return (
    <div className="card-contact">
        <Cards />
    </div>
  )
}

function Cards(){
   return cardDetails.map(card => {
        return (
            <div className="contact-card-wrap" key={card.id}>
               <img loading='lazy' src={card.img} alt="img" />
               <span className="card-title">{card.title.toUpperCase()}</span>
               <span className="card-details">{card.details}</span>
               <span className="card-email"><a href="mailto:{card.email}">{card.email}</a></span>
            </div>
        )
    });
}

export default CardContact