import { createAsyncThunk } from '@reduxjs/toolkit';
import { read, utils } from 'xlsx';
import { setJobs } from './jobs';
import FILE from "../assets/job/job-template.ods";

export const fetchJobs = createAsyncThunk('jobs/fetchJobs', async (_, thunkAPI) => {
  try {
    const url = FILE; // Ensure FILE is defined and correct
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    const workbook = read(arrayBuffer);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const data = utils.sheet_to_json(worksheet);
    thunkAPI.dispatch(setJobs(data));
  } catch (error) {
    console.error('Error fetching or processing data:', error);
  }
});
