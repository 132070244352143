import React from "react";
import "./_t&cs-policy.css";
import Nav from "./Nav";
import {ScrollToTop} from '../core/ScrollToTop'

function TermsOfService() {
    return (
       <>
       <Nav/>      
       <ScrollToTop />
        <div className="pt-container">
            <div className="pt-content-bg"></div>
            <div className="pt-content">
                <div id="pt-header">
                    <div id="pt-heading">
                        <span>PLATFORM TERMS OF SERVICE </span>
                    </div>
                </div>
                <hr/>
               <p> This Platform, the Mobile Application and the SOLV Services are managed, operated and provided by Solvezy Technology Ghana Ltd under the brand name “SOLV”.</p>

                <p> “We”, “our” and “us” refer to SOLV. “You” and “your” refer to the User and/ or his/her/its Authorized Representative, as applicable. All capitalised words found in these Terms of Service (“Terms”) are defined throughout the Terms.</p>
                
                <p> 
                    The SOLV Services are made available solely for business entities based in Ghana. By accessing the Website or using the SOLV Services, the User agrees that the User and the Authorized Representative have read, understood and agrees to be bound by these Terms, our Privacy Policy, which will be made available to you upon your registration, and any applicable Third Party Terms (together, this “Agreement”).{" "}
                     <b>It is important to read the entire Agreement carefully as some of the provisions affect your legal rights and obligations.</b>
                </p>
                
                <p> Further, if you are accessing the SOLV Services through the Mobile Application, then while these Terms shall apply to such use, there may be additional terms (such as the terms imposed by App Store (iOS), Android's (Google) Play Store, Microsoft's store) from time to time
                    which will govern the use of Mobile Application. These additional terms to the extent applicable are hereby deemed to be incorporated in these Terms by way of reference
                </p>
                
                <p>
                    You may not use the SOLV Services if you do not accept the terms ofthis Agreement. If you do not agree to be bound by these Terms and thePrivacy Policy, you should not use the Website. It is stronglyrecommended for you to return to this page periodically and review the
                    most current version of the Terms in force. SOLV reserves the right at any time, at its sole discretion, to change or otherwise modify the Terms without prior notice, and your continued access or use of this Website signifies your assent/ratification of the updated or modified
                    Terms. If you object to these Terms or any subsequent modifications to these Terms in any way, your only recourse is to immediately terminate use of the Website. We may require you to agree to additional terms (if any) between you and SOLV in connection with specific services
                    that you may avail from us from time to time
                </p>

                <div>
                    <ol>
                        <li>
                            <b>DEFINITIONS </b>
                        </li>
                            <p> <b>Account</b> shall mean the account created by the User on the Website after successful completion of registration </p>
                            <p> <b>Agreement</b> shall have the meaning as assigned to such term in the foregoing paragraph </p> 
                            <p> <b>Anchors</b> shall mean entities to whom Dealers purchase products from. </p> 
                            <p> <b>Authorized Representative</b> shall mean the employee or authorised representative of the User who is specifically authorized to register on the Website on behalf of the User to assist the User to avail the Third Party Service Offerings </p> 
                            <p> <b>BILS Services</b> shall mean the business instalment loan services provided by the Lenders to the Users on the Platform. </p>
                            <p> <b>BOG</b> means the Bank of Ghana</p>
                            <p> <b>Content </b> shall have its meaning as provided under clause 6.2</p>
                            <p> <b>CRB</b> shall mean a credit reference bureau that has been granted a certificate of registration under the Credit Reporting Act, 2007 (Act 726).</p>
                            <p> <b>Credit Information</b> shall mean collectively the credit information as defined under the Credit Reporting Act, 2007 (Act 726), credit information report, credit score, and other credit data and analysis reports received from the CRBs.</p>
                            <p> <b>Dealers</b> shall mean entities who purchase products from Anchors. </p>
                            <p> <b>Financial Services</b> shall mean financial solutions such as unsecured BILS, SCF Services, and such other services provided by Lenders to the Users on the Platform. </p> 
                            <p> <b>Insurance Service</b> shall mean transit insurance, life  nsurance, personal accident coverage etc offered by Third Party Service Providers to Users through the Platform, by way of which the Users can indemnify themselves against financial losses while availing Third Party Services on the Platform. </p>
                            <p> <b>National Identification Card (Ghana Card) Database</b> means the database of National Identification Cards (Ghana Card) issued by the National Identification Authority (NIA) to Ghanaians and permanently resident foreign nationals for the purpose of identification. under the Ministry of State for Immigration and Registration of Persons and its successors. </p>
                            <p> <b>GRA TIN Checker</b> means the Ghana Revenue Authority TIN Checker System set up and maintained by the Ghana Revenue Authority and its successors.</p>
                            <p> <b>KYC</b> means “know your customer” searches to be conducted by SOLV and/or any other Third Party Service Provider by such means as deemed necessary which are required in order to provide the SOLV Services to the User. SOLV may at its discretion conduct KYC searches on behalf of Third Party Services Providers.</p>
                            <p> <b>Lender</b> shall mean a bank licenced to carry out banking activities under the applicable banking laws in Ghana, or similarly licensed financial institution onboarded on the Platform as Third Party Service Providers to provide Financial Services.</p>
                            <p> <b>Logistics Services</b> shall mean services in relation to logistics, deliveries, shipments, packers and movers provided by Third Party Service Providers.</p>
                            <p> <b>Mobile Application</b> shall mean the mobile application belonging to SOLV which is developed and designed to run on various mobile operating systems, for providing the SOLV Services.</p>
                            <p> <b>Organisation</b> shall mean a limited liability company, or a sole proprietorship/partnership firm, as the case may be, duly incorporated under the Companies Act, 2019 (Act 992) of Ghana, validly existing and in good standing, and has all requisite power and authority to own and operate its business and properties and to carry on its business as such business is now being conducted</p>
                            <p> <b>Identification Document</b> shall mean, National Identity card (Ghana Card, Passport, Voters ID Card, etc.) or any other officially valid document as per the relevant laws and regulations on Know Your Customer Procedures, as amended from time to time.</p>
                            <p> <b>Information</b> shall mean all the details we hold or collect about you, your transactions, your financial information, your personal information, and information about you obtained from you, your interactions and dealings with us and any third party, including information received from third parties and information collected through your use of the SOLV Services.</p>
                            <p> <b>Partner</b> shall mean third party partners of SOLV from whom SOLV may obtain certain services in relation to the Website and/or the SOLV Services, including but not limited to, the Financial Institution partners, verification companies, information repositories and such other parties as deemed necessary by SOLV in order to provide the SOLV Services.</p>
                            <p> <b>Platform</b> shall mean the digital platform having the tradename and brand name SOLV, as operated and maintained by SOLV, the front-end version of which is accessible through the Website.</p>
                            <p> <b>Privacy Policy</b> shall mean the SOLV privacy policy as provided by us to you when you register for the SOLV Services</p>
                            <p> <b>Party</b> shall mean, in relation to SOLV, its representatives, agents, and any of their respective officers, directors, equity holders, employees, parent entities, subsidiaries, affiliated entities, representatives, agents and licensors.</p>
                            <p> <b>SCF Services</b> shall mean the supply chain financing services provided by the Lenders to the Users on the Platform.</p>
                            <p> <b>SOLV Services</b> shall mean the service of providing the Website and the Platform which facilitates and connects Users to 
                                <span> (i) other Users. </span> 
                                <span> (ii) various Third Party Service Providers providing Financial Services, logistics, accounting, human resources and payroll management services etc. to the User, and any related services in relation to the Third Party Service Offerings and</span>
                                <span> (iii) any KYC services provided by SOLV to Third Party Service Providers. </span></p>
                            <p> <b>Third Party Service Offerings</b> shall mean the Financial Services, Insurance Services, Logistics Services, human resources, accounting and other services provided by Third Party Service Providers to Users on the Website.</p>
                            <p> <b>Third Party Service Providers</b> shall mean service providers including Lenders, prepaid card issuers, sellers, service providers, insurers and logistics providers who are onboarded on the Platform and from whom the Users can avail the Third-Party Service Offerings</p>
                            <p> <b>Third Party Terms</b> shall mean the additional terms and conditions that the User may have to agree separately with the Third-Party Service Providers to avail any Third Party Service Offerings.</p>
                            <p> <b>User</b> shall mean any Authorized Representative/Organisation (as the case may be) registered on the Website being a business entity in Ghana for using the SOLV Services to avail any Third Party Service Offerings and shall include Anchors and Dealers under the SCF Services.</p>
                            <p> <b>User Consent</b> shall mean the consent procured from the User (which is obtained upon the User’s acceptance of these Terms) in relation to access to information by SOLV on behalf of the User from a Partner such as from the CRB, GhanaCard database, GRA TIN Checker.</p>
                            <p> <b>User Data</b> shall mean any data submitted by User or the Authorized Representative on behalf of the User, to or in relation to the SOLV Services or Third Party Service Offerings, including Personal Information and Sensitive Personal Information, as mentioned in the Privacy Policy.</p>
                            <p> <b>Website</b> shall mean the domain{" "} <a href="https://www.solv.com.gh" target="_blank" rel="noreferrer"> {" "} </a>{" "}and/or the Mobile Application.</p>
                            <p> <b>SOLV</b> and the User shall as the context so requires, be individually referred to as a<b>"Party"</b> and collectively as the <b>"Parties"</b>.</p>
                        
                        <li><b>REGISTRATION AND USAGE OF WEBSITE</b></li>
                        <p> 
                            2.1 The Website is freely accessible currently, however, prior to accessing any of the SOLV Service(s) as a User, an organization/ individual will have to register on the Website and create an Account. An Authorized Representative can register the User using 
                            a valid mobile number and corporate email address and accept the Terms and the Privacy Policy on behalf of a User. A "one time password" (“OTP”) will be sent to the mobile number of the Authorized Representative and a verification email will be sent to
                            the valid email address provided by the Authorized Representative. You will need to
                            <span>(i) enter the OTP for validating your mobile number; and{" "} </span> 
                            <span>(ii) verify the email for validating your email address, to activate and access your Account. </span>
                            Once activated, you can use your mobile number together with your password to access the SOLV Services (subject to additional conditions to access specific Third Party Service Offerings as may be specified hereunder) and it will remain active until terminated
                            in accordance with these Terms. An Account created by an Authorized Representative without providing necessary support documentation, such as board resolution or resolution of partners etc, as may be required by SOLV, for appropriate authorization
                            from the User will be suspended and terminated.
                        </p>
                        <p> 2.2 You will also be required to submit certain documents stipulated as per SOLV’s policies as applicable from time to time, for User Verification such as Certificate of Incorporation/Business Registration Number, GRA TIN, directors/
                            partner KYC documents (e.g. ID card, Passport, Ghana Card or any other government issued identity proof), bank statements of specific duration, board resolution, income statement, sales and purchase data, and photos etc. Please refer to Annexure A and Annexure B of this Agreement for further details on the documents
                            that we may collect. We may also collect your Mobile Money Wallet Statements, bank statements, etc. for the purpose of you accessing the Financial Services provided on the SOLV platform, with your specific consent.
                        </p>
                        <p>
                            2.3 You hereby expressly authorise SOLV to use such documents, store and process such information for the purposes of this Agreement. To the extent permitted under applicable laws, we will also fetch documents and information pertaining to you in relation to the goods and service taxes, income tax reports, etc. from the
                            records maintained in Government registration authorities as part of the User Verification. Further details of the information / documents that we collect and the manner in which we use the information/ documents are detailed in our Privacy Policy available on our Website, which forms part of these Terms and
                            shall stand incorporated therein. If these Terms read with our Privacy Policy are not acceptable to you, please do not provide any of your information to SOLV. However, in such event, we may not be able to provide you with the SOLV Services or some parts of it, as may be applicable.
                        </p>
                        <p>
                            2.4 The details and/or documents requested on the Website and the annexures attached to these Terms, are not exhaustive of requirements and SOLV reserves the right to requisition and/or extract additional documents and / or details as may be deemed necessary by SOLV in its sole discretion, to complete User
                            Verification or KYC. In the event the details or documents submitted for creating Account, or for availing the SOLV Services, are incomplete or discrepant, SOLV will request additional information / documentation through email / SMS sent to the email address / mobile number as provided by you during your attempt to
                            register on the Website. In case the discrepancies remain unresolved or if SOLV is unable to successfully complete its User Verification in accordance with its policies, SOLV shall have the right to reject your request for registering with the Website and/or availing the Third Party Service Offerings. SOLV reserves
                            the absolute discretion to accept or reject your request for creating the Account towards availing SOLV Services, and any Third Party Service Offerings, without assigning any reasons for the same.
                        </p>
                        <p>
                            2.5 By registering and creating an Account with SOLV, the User certifies that:
                            <br />
                            (a) The User/Authorized Representative have created the Account for your own personal/ your Organisation's use and that the information and documents you provide to SOLV will be your/ your Organisation's details (and not that of any third person or entity).
                            <br />
                            (b) All information and documents that you provide to SOLV at any time, including during the course of any transaction with other Users, are true, complete and accurate and any such information is supported by genuine and valid documents submitted to SOLV. You further confirm that you will keep all such information and
                            documents current, complete, accurate and valid at all times for as long as your Account remains valid. You can log in to your Account to review and modify any changed details. You may be required to submit documentary proof as required by SOLV’S policies from time to time to authenticate the modified details
                            and may not be able to use the SOLV Services until verification of the changed details is successfully completed by SOLV. You hereby agree that you will indemnify SOLV for any loss, damage, claim, action, costs, charges and expenses which SOLV may suffer or incur as a result of acting on, or relying on the information and
                            documents provided by you.
                            <br />
                            (c) The Authorized Representative has attained majority age, is of employable age and has been duly authorized to act on behalf of the User for the purpose of using the SOLV Services.
                            <br />
                            (d) You will be responsible for safeguarding the security and confidentiality of your Account and password and you will not share, assign or transfer your Account or reveal your password to any third party or do anything else that might jeopardize the security of your Account; and that you will be solely responsible
                            and liable for all actions/instructions initiated using your Account. You may be held liable for losses incurred by SOLV or any other User due to authorized or unauthorized use of your Account as a result of your failure in keeping your Account information
                            secure and confidential and/or not promptly notifying SOLV in the event of any beach of security.
                            <br />
                            (e) You will not create more than one Account for the same entity. Provided however that you can invite members of your organization as team members for accessing the same account in the manner elaborated in the Platform.
                            <br />
                            (f) You will comply at all times with all anti-corruption, anti-slavery, anti-bribery and anti-child labour laws and shall not engage in practices involving child labour or slavery.
                            <br />
                            (g) SOLV may suspend or terminate your Account or your use of the SOLV Services at any time without notice, if we suspect that any information / documents/ authorization that you have provided are untrue, inaccurate, not current, or incomplete or if you are found
                            to be in breach of any of these Terms.
                            <span>
                                (i) You consent to SOLV collecting, using and processing your Information for the purposes set out in these Terms and the Privacy Policy. You may withdraw this consent at any time by notifying us in accordance with our Privacy Policy.
                            </span>
                            In the event the User is required to provide any security or collateral (in the form of immovable or movable asset) in relation to a Third Party Service Offering, the User shall provide true, complete and accurate information pertaining to such asset to such Third Party Service Party Provider who has requested such security
                            collateral to demonstrate that the User has the sole right, title and interest to such asset. The offer letter or such other documentation that details the security or collateral will be uploaded to the Platform.
                            <br />
                        </p>
                        <li>
                            <b>SCOPE OF SERVICES AND LIMITATIONS </b>
                        </li>
                            <br />
                            <p>
                                3.1 The SOLV Services provided by SOLV are available only for use in the Republic of Ghana. The Website is merely an online platform and SOLV only acts as an intermediary to enable Users and the Third Party Service Providers to interact with each other for their transactions and for availing the Third Party Service Offerings provided herein. You hereby agree and accept that SOLV
                                is not and shall not be a party to any agreements entered into or executed between the Users and Third Party Service Providers. Further, you agree that SOLV has no control over the conduct of any Third Party Service Providers or their Third Party Service Offerings and disclaims any and all liability whatsoever arising
                                in this regard to the maximum extent permitted by applicable law
                            </p>
                            <p>
                                3.2 You may be required to accept and agree to be bound by Third Party Terms applicable to the specific Third Party Service Offerings you choose. The applicable Third Party Terms may be set out at the point of access to the relevant Third Party Service Offerings. These Third Party Terms are in addition to, and not in
                                derogation of these Terms.
                            </p>
                            <p>
                                3.3 For details of the various Third Party Service Offerings, the scope of such service provided, the term of the relevant Third Party Service Offering and the corresponding charges applicable, please refer to the relevant Third Party Terms
                            </p>
                            <p>
                                3.4 There may be certain Partner terms and privacy policies which may be applicable to the SOLV Services, which may be appended to these Terms, set out at the point of access to the relevant Partner portals or referred at such other appropriate place. You are also responsible for reading them and complying with them. Any
                                data that you submit to such Partners shall be governed by the Partner privacy policies. SOLV has no control over the conduct of any Partners and their use of data and disclaims any and all liability in this regard to the maximum extent permitted by applicable law
                            </p>
                            <p>
                                3.5 All transactions undertaken on your behalf by SOLV will be on the basis of your express instructions/prior approval and will be strictly on a non-discretionary basis. SOLV only facilitates the availing of Third Party Service Offerings from the Third Party Service Providers. You fully understand and agree that the final
                                decision of providing the Third Party Service Offering will rest exclusively with the Third Party Service Provider or the User concerned, respectively and the Third Party Service Provider or the User concerned is not obliged to accept any advice/recommendations provided by SOLV. SOLV is not responsible
                                for any non-performance or breach of any contract entered into between Third Party Service Provider and Users and any transaction between two or more Users on the Platform. SOLV cannot and does not guarantee that the concerned Third Party Service Provider shall perform any transaction pursuant to the usage of Website.
                                SOLV shall not and is not required to mediate or resolve any dispute or disagreement between Third Party Service Provider and the Users or inter-see between the Users. Subject to applicable laws, SOLV shall also have the right, but not the obligation, to provide such information and documents, as reasonably required by
                                any User in the course of its dispute or disagreement with a Third Party Service Provider or any other User.
                            </p>
                            <p>
                                3.6 By accepting these Terms, you hereby request and expressly authorize SOLV and its employees or agents to communicate with you, and accept instructions from you or with your approval.
                                <span> (i)over the telephone by SMS or calls at the number you provide to SOLV.{" "} </span> 
                                <span> (ii) by email at the email address you provide to SOLV. </span>
                                <span>(iii) over video calls generated on the Platform. </span>
                                <span> (iv) in writing (including through documents uploaded by you through your Account or otherwise). </span>
                                <span> (v) through 'click to accept' contracts and forms, and/or any other mode and form as may be determined appropriate by SOLV from time to time. </span>{" "}
                                You hereby consent to SOLV, at its sole discretion, recording all telephone calls between you and SOLV representative for any purpose whatsoever, however subject to applicable laws, and any such records of SOLV shall be conclusive and binding evidence of the fact and content of such communication. It is your
                                responsibility to immediately notify SOLV of any change in your email address or telephone number. You hereby confirm that instructions / approval so provided by you will be valid, effective and binding on you without any further enquiry or due diligence as to identity of the person purporting to give such
                                instruction / approval. SOLV may, in its sole discretion, seek further confirmation of any instruction / approval given or purporting to originate from you. You hereby specifically consent that SOLV shall be entitled to use any User Data or data/information SOLV derives from your transaction or usage
                                patterns on the Platform for any cross selling of service offerings or promotion of new offerings in the manner determined by SOLV from time to time, provided that you have not withdrawn your consent.
                            </p>
                            <p>
                                3.7 For as long as your Account remains active, SOLV may, from time to time, recommend SOLV Services or Third Party Service Offerings. We shall not be held liable or responsible for any recommendation we provide regarding any Third Party Service Provider or Third Party Service Offering. Any such recommendation is not an endorsement, certification or guarantee by SOLV about
                                any Third Party Service Provider and is only intended to be useful information for you to make an informed decision. The ultimate decision regarding the appropriateness of any Third Party Service Offering is exclusively yours and you should exercise due caution and/or seek independent advice/due diligence before entering into any financial decision. SOLV shall not be a party to and shall not
                                be responsible or liable for any transactions, interactions, conduct or communications between you and Third Party Service Provider, on or through the Website or otherwise. SOLV may receive a facilitation fee from the Third Party Service Providers and any other third party banks / credit institutions and you hereby
                                confirm that you have no objection to the same.
                            </p>
                            <p>
                                3.8 SOLV may at its discretion introduce any new SOLV Services and Third Party Service Offerings, from time to time
                            </p>
                            <p>
                                3.9 SOLV shall not be liable for any failure or delay to perform any of its obligations if such performance is prevented, hindered or delayed, in part or entirely, by any event beyond the reasonable control of SOLV, including without limitation, fire, flood, explosion, acts of god, terrorist acts, civil commotion, strikes or industrial action of any kind, riots, insurrection,
                                war, acts of government, acts of third parties beyond SOLV’s reasonable control, security breaches, power shut down, faults or failures in telecommunication or internet services or unauthorized access to computer systems <b>(“Force Majeure Event”)</b>.
                            </p>
                            <p>
                                3.10 For providing any of the SOLV Services or Third Party Services, SOLV may be required to ascertain your location or geolocation. You hereby provide your consent for SOLV and Partners to ascertain, track, record and share your location and geolocation in accordance with applicable laws.
                            </p>
                        <li> <b>SPECIFIC TERMS FOR THIRD PARTY SERVICES</b></li>
                        <p>
                            <b>4.1 Financial Services </b>
                        </p>
                        <div className="pt-service">
                            <p>{" "}A.<i>    <b> Loan Services</b></i></p>
                            <p>
                                (a) When you apply for or access any Financial Services, we may require you to submit certain documents in accordance with Annexure A to verify your Organisation and its Authorised Representative. We may also require you to update your KYC documents, as and when required. We may also require you to
                                provide us with or give us access to your bank account statements. We may receive the personal information of the Authorised Representative such as name, gender, date of birth, address, phone number etc., pursuant to such KYC verification and you hereby consent to the same. We may also undertake video
                                KYC verification, if permitted by BOG. We may also require you to submit any other document as may be required by the relevant Third Party Service Provider from time to time
                            </p>
                            <p>
                                (b) The Financial Services provided to you may be based on your Credit Information, which is maintained by various authorized CRBs in Ghana and which may be obtained by our Financial Institution partners from such CRBs on your behalf with User Consent and information derived by using SOLV's proprietary credit analysis algorithms, and any other financial information
                                that you may choose to provide to SOLV. If the information and documents provided to our Financial Institution partners (by the concerned CRBs or you) are incorrect or incomplete, it will adversely affect the quality of the SOLV Services, including any recommendation provided by us regarding the Third Party Service
                                Providers
                            </p>
                        </div>
                        <div className="pt-service">
                            <p>B.<i>    <b> Services for Supply Chain Financing (SCF)</b></i> </p>
                            <p>
                                (a) For the purposes of this clause, "you" shall include, Anchors and Dealers registered on the Platform.
                            </p>
                            <p>
                                (b) When an Anchor or Dealer applies for or accesses any SCF Services on our Platform, we may require submission of certain documents in accordance with Annexure B to verify the Organisation and the owner. We may also require the dealer and Anchor as well as their owner to submit KYC documents, in
                                accordance with Annexure A. We may also require Anchor/Dealer to provide net banking details and/or give us access to their bank account statements. We may receive the personal information of the owner such as name, gender, date of birth, address, phone number etc., pursuant to such KYC verification and you hereby
                                consent to the same.
                            </p>
                            <p>
                                (c) As a part of the SCF Service provided, SOLV’s Financial Institution partners may collect Credit Information of the Anchor/Dealer or their owners, which is maintained by various authorized CRBs in Ghana and which we obtain from such CRBs on their behalf with User Consent. As an additional step they may
                                be directed to upload registered mobile number for the Credit Information and accept the terms and conditions.
                            </p>
                        </div>
                        <div className="pt-service">
                            <p> C. <i>     <b> General</b> </i> </p>
                            <p>
                                SOLV does not guarantee that your application to avail Financial Services will be successful. The decision to provide you with the Financial Services lies with the Third Party Service Provider who makes the relevant Third Party Offering available to you. Please read the applicable Third Party Terms carefully to understand the eligibility criteria and conditions for these Third Party Offerings. Prior to providing any facility, the
                                Lenders may conduct credit approval checks on you and collect Credit Information. The Users hereby expressly authorize and provide consent to the Lenders to collect their Credit Information from any CRB and conduct the relevant credit checks. In the event you are required to enter into a separate agreement with Lenders for availing the Financial Services, you shall not
                                agree on any terms contrary to the terms agreed with SOLV. Further, in the event of any conflict or inconsistency between the terms you agreed with SOLV and the terms with Lenders, the terms you agreed with SOLV shall prevail.
                            </p>
                        </div>
                        <div>
                            <p> <b>4.2 Insurance Services</b></p>
                            <p>
                                (a) Except for certain transit insurance services provided along with the Logistics Services, the Insurance Services are provided under group insurance schemes provided by registered insurers. SOLV is not an insurer or an insurance intermediary and is merely acting as a group manager to administer these schemes.
                                SOLV will therefore not be liable for any insurance payment in case of any issue on quality, return, short shipment, breakage or any other issue covered under the Insurance Services.
                            </p>
                            <p>
                                (b) Insurance Services are governed by the terms and conditions provided by the relevant insurers available on their respective websites should you choose to avail of Insurance Services.
                            </p>
                            <p>
                                (c) Transit insurance is available to you only when you avail Logistics Services for certain categories of services or services exceeding a certain minimum value and will be subject to such terms and conditions as applicable at the time of booking. To apply for the Insurance Services, you may need to
                                submit certain documents and information with us. We may share these documents and information with insurers to help the insurer determine whether you are eligible to receive Insurance Services and comply with its legal requirements.
                            </p>
                            <p>
                                (d) The insurer will determine your eligibility to avail the relevant Insurance Services in accordance with its internal policies. The insurer reserves the right to withhold providing Insurance Services to you at its discretion. We will charge you such amount as mentioned in the Platform from time to time for
                                providing the Insurance Services. Further, we will only be liable to make payment towards any of your claims, to the extent such claim is processed by the insurer. In the event any claim is rejected by the insurer, or any claim is only partially allowed by the insurer, we will not be liable to pay the balance
                                amount remaining unpaid.
                            </p>
                        </div>
                        <div>
                            <p><li>    <b>PERMITTED USE OF WEBSITE</b></li> </p>
                            <p>
                                5.1 You may use the Website and the SOLV Services solely for your own use, subject to the terms of this Agreement that you may accept and agree to be bound by. We grant you a non-sub-licensable, revocable, non-transferable, non-exclusive,
                                limited period license to use Website, but solely as necessary to use the SOLV Services and in accordance with the Agreement. All of our rights not expressly granted by this license are hereby retained.
                            </p>
                            <p>
                                5.2 All content, data, design, information or other materials available on and underlying the Website (collectively “Content”), including but not limited to software underlying the Website or which are required for accessing or using the Website, images, text, layouts, arrangements, displays,
                                illustrations, photographs, graphics, audio and video clips, HTML files and other content, logos, trademarks and other trademarks that may be used on the Website are the property of SOLV and/or its licensors and are protected by copyright and/or other applicable intellectual property right laws. You are not
                                permitted to copy, reproduce, scrap, publish, perform, communicate to the public in any manner whatsoever, transmit, sell, license, distribute or transfer in any manner whatsoever, adapt, modify, translate, disassemble, decompile or reverse engineer, create derivative works from, or in any way use or
                                exploit the Website, the SOLV Services, the Content or any part thereof. You shall not remove or obliterate any copyright, trademark or proprietary rights notices from the Content or the Website and shall reproduce all such notices on all authorized copies of the Content. Any use of the Website, the Service
                                and/or Content in violation of these Terms may violate copyright, trademark and other applicable laws, and could result in appropriate legal action. Notwithstanding anything contained in this Agreement, SOLV may at its discretion, provide the Users with an option to replicate and use the Content, including
                                images, as available on the Platform, which may have been provided by SOLV or other Users, for cataloguing the products on the Platform or any other permitted purposes. In the event SOLV provides such feature, you hereby acknowledge and agree that the other Users may use the Content provided by you; and upon such
                                usage, SOLV and the relevant User(s) shall not be liable in any manner under applicable laws or otherwise and you shall not have any claim or objection whatsoever against such usage.
                            </p>
                            <p>
                                5.3 SOLV's role in providing the Platform to you is limited to that of a facilitator. SOLV does not initiate any transactions entered between Users or between Users and Third Party Service Providers on its Platform. SOLV does not select or modify any information provided by Users, including but not limited to
                                information with respect to transaction, conversations and listings. SOLV will not be liable for the content generated or published in listings, chats or conversations between Users or with Partners on its Platform.
                            </p>
                            <p>
                                5.4 You shall not use the Website, the SOLV Services, the Content or any part thereof for any purpose that is unlawful or not permitted by these Terms. You will not display or upload any photo, picture, illustration or graphic representation which may violate the proprietary rights or copyright of any other person
                                and you shall ensure that you are authorized to upload or display such materials. Without prejudice to the generality of the above, you shall not use the Website, the SOLV Services, the Content or any part thereof to host, display, upload, modify, publish, transmit, update or share any information that:
                            </p>
                            <span> (a) belongs to another person and to which you do not have any right to; </span>
                            <br />
                            <span>(b) impersonate another person;</span>
                            <br />
                            <span>
                                (c) is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money
                                laundering or gambling, or otherwise unlawful in any manner whatever;
                            </span>
                            <br />
                            <span>(d) harm minors in any way;</span>
                            <br />
                            <span>(e) infringes any patent, trademark, copyright or other proprietary rights;</span>
                            <br/>
                            <span>(f) violates any law for the time being in force or deceives or  misleads SOLV about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</span>
                            <br/>
                            <span>(g) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;</span>
                            <br />
                            <span> (h) threatens the unity, integrity, defense, security or sovereignty of Ghana, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation; </span>
                            <br />
                            <span> (i) is unsolicited or unauthorized advertising, junk mail, or spam; or </span>
                            <br />
                            <span>
                                (j) sells any counterfeit goods or illegal goods or restricted goods on the Platform. Further, you shall not use the SOLV Platform if you deal in any counterfeit goods or illegal goods or restricted goods or services as may be prescribed from time to time by SOLV. If you are, or if we have reasonable cause to
                                suspect that you are, in violation of this clause, SOLV reserves the right to immediately restrict, suspend, or terminate your access to the Website and/or use of the SOLV Services. SOLV will not be responsible for any claims arising out of any violation of these Terms that have taken place without its knowledge.
                            </span>
                            <br />
                            <p>
                                5.5 In the event of any downtime or data breach, hacking or such other incident on the Platform or Website, which may or may not adversely affect you, your sole remedy shall be as set out under paragraph 15.1 below and you shall not directly or indirectly post any offensive or derogatory content against SOLV or any of
                                its affiliates, in any media including social media, in any form whatsoever.
                            </p>
                            <p>
                                5.6 Further, by availing the SOLV Services, you hereby expressly acknowledge that SOLV shall at its discretion, be entitled to use your name, tradename, brand name, logos etc in any marketing materials, promotional materials or advertisements of SOLV or
                                its Platform and SOLV shall deemed to have acquired a license for such usage and you shall not raise any objection in this regard.
                            </p>
                        </div>
                        <div>
                            <p> <li><b>CHARGES</b> </li> </p>
                            <p>
                                6.1 SOLV shall be entitled to charge fees for the SOLV Services as per the respective SOLV Service that you subscribe/ avail on the Website. SOLV will deduct the applicable fees when you make any payment in such other manner as communicated to you from time to time. The fees as applicable from time to time shall be
                                detailed on our Website or as communicated to you from time to time including by way of email. Fees charged for SOLV Services that involve Third Party Service Providers may be set out on the Website, via email or in such documentation provided for that particular Third Party Service.
                            </p>
                            <p>
                                6.2 The fees paid to SOLV are non-refundable. SOLV’s records shall be final and binding as to any such commencement of the SOLV Service. SOLV may also receive a referral / facilitation fee from the Third Party Service Provider and/or other third party banks / other institutions for
                                <br />
                                (a) assisting with the settlement of outstanding amounts or dues  owed to them by you and / or <br />
                                (b) for referring their services to you. By accepting these
                                Terms, you confirm that you have no objection to the same.
                            </p>
                            <p>
                                6.3 In the event any Third Party Service Provider or a User charges a fee for a transaction, notwithstanding the unsuccessful consummation of such transaction, SOLV shall not be liable to Users for reimbursement or refund of such fee.
                            </p>
                            <p> 
                                6.4 All forms of payment to Solv staff is prohibited.As a means for compliance with reporting any breaches or any other potential misconduct involving Solv, You may use a secure Speaking Up channel at to{" "}
                                <a href="mailto:compliance@solv.com.gh"> compliance@solv.com.gh </a>{" "} and or to any Solv Ghana employee, based on which an enquiry will be raised.
                            </p>
                        </div>
                        <div>
                            <p><li><b>LOYALTY AND REFERRAL PROGRAMMMES</b></li> </p>
                            <p>  <b>7.1 Loyalty Programme</b> </p>
                            <span>
                                (a) We may offer Loyalty Programme for Users on the Platform from time to time. The Loyalty Programme seeks to reward Users who avail the benefits of SOLV Services. Eligible Users may redeem their loyalty points against Third Party Offerings or
                                transactions with other Users on the Platform. Users can participate in the Loyalty Programme once they have created their Accounts.
                            </span>
                            <br />
                            <span>
                                (b) We may grant you loyalty points when you refer other Users to the Website. You may also earn loyalty points when you avail Third Party Offerings or undertake commercial transactions with other Users through the Website. You will be able to view the total number of loyalty points you have accrued when you view your Account information.
                            </span>
                            <br />
                            <span>
                                (c) The loyalty points will be valid only on the Website. They cannot be redeemed offline against other Users, Third Party Service Providers, or us.{" "}
                            </span>
                            <br />
                            <span>
                                (d) The loyalty points will be awarded solely at our discretion= and the options available to redeem them are also at our discretion. We will determine the rules for allocating loyalty= points. Except as otherwise expressly prohibited or limited by= applicable laws, SOLV has the right to change, limit, modify or
                                cancel the Loyalty Programme at any time, with or without notice.
                            </span>
                            <br />
                            <span>
                                (e) The loyalty points are the property of SOLV. You cannot transfer or sell or barter the loyalty points to any other person, including Users of the Platform.
                            </span>
                            <br />
                            <span>
                                (f) We may need to share certain information about you with our Partners in order to offer the Loyalty Programme to you. Please refer to the Privacy Policy to understand the nature of information which may be shared.
                            </span>
                            <br />
                            <p> <b>7.2 Referral Programme</b> </p>
                            We may also offer Referral Programme on the Platform from time to time for rewarding Users who successfully refer other business entities to onboard as Users. The details of the current Referral Programme offered by us are set out here.
                        </div>
                        <div> <p> <li> <b>PRIVACY</b> </li></p>
                            <p>
                                The Privacy Policy relating to our collection, processing, use, storage and disclosure of User Data is incorporated by this reference into, and made a part of, these Terms. By agreeing to the Terms, you agree to the use of the User Data and confidential information as per the Privacy Policy.
                            </p>
                            <p> <li>     <b>TERMINATION</b> </li> </p>
                            <p> 9.1 This Agreement is binding from the time you access this Website and will continue until either you or SOLV terminates this Agreement.
                            </p>
                            <p>
                                9.2 SOLV may terminate the Agreement and de-activate your Account forthwith by notice to you at your email address registered with us in the event that you are in breach of this Agreement.
                            </p>
                            <p>
                                9.3 SOLV may also terminate this Agreement and de-activate your Account for any reason by giving you notice of 14 (fourteen) days or such other appropriate time period, addressed to your email address registered with us.
                            </p>
                            <p>
                                9.4 You may terminate this Agreement and request deactivation of your Account by written notice by email addressed to SOLV at <a href="mailto:customerservice@solv.com.gh">     {" "}     customerservice@solv.com.gh</a>
                                . Your Account will be deactivated within 30 working days from receipt of such email.
                            </p>
                            <p>
                                9.5 Upon termination, your right and license to access and/or use the Website or avail any the SOLV Services immediately ceases; your Account will be deactivated. Further, your personal information that is in the possession of SOLV will be dealt with in accordance with our Privacy Policy.
                            </p>
                            <p>
                                9.6 Notwithstanding the termination rights stated herein, the obligations you have with SOLV and any Third Party Service Provider, with regards to ongoing SOLV Services, will survive the termination of this Agreement until such time the SOLV Services granted to you expire or are terminated by SOLV or the
                                respective Third Party Service Provider.
                            </p>
                        </div>
                        <div>
                            <p><li>    <b>REPRESENTATIONS AND WARRANTIES</b></li></p>
                            <p>10.1 The User represents and warrants that:</p>
                            <p>
                                (a) (i) if it is an entity, it is a limited liability company, or a sole proprietorship/partnership firm, as the case may be, duly incorporated under the Companies Act, 2019 (Act 992) of Ghana, validly existing and in good standing, and has all requisite power and authority to own and operate its business
                                and properties and to carry on its business as such business is now being conducted; or <br /> 
                                (ii) if an individual he/she is not insolvent and has all necessary permits to carry out his/her business;
                            </p>
                            <p>
                                (b) he/she/it has full legal capacity and has obtained all necessary statutory and regulatory permissions, approvals and permits for the running and operation of its establishment, for the conduct of its business and to execute, deliver and perform this Agreement and has been or will be duly authorized by all
                                necessary corporate actions and if it is an entity, it has authorised the Authorised Representative with necessary authorisations to perform actions on its behalf for the purposes of the SOLV Services and the Third Party Service Offerings;
                            </p>
                            <p>
                                (c) this Agreement has been duly executed and forms legal, valid and binding obligations upon the User, enforceable in accordance with its terms;
                            </p>
                            <p>
                                (d) no claim, litigation, arbitration, claim, suit, action, investigation or proceeding or order, judgment, injunction, decree, award, settlement or stipulation of or before any arbitrator, tribunal or government authority, is pending or
                                outstanding against it/her/him affecting its ability to enter into or perform this Agreement.
                            </p>
                            <br />
                            In the event that these Terms are being entered into by an Authorized Representative, then said individual represents and warrants that it has validly entered into the Agreement and has the legal power to do so, for and on behalf of the User.
                            <p>
                                10.2 The Users shall not avail any SOLV Services if they are the subject of any sanctions imposed by any regulatory authority(ies). The Users must comply with all other export and re-export restrictions that may apply to goods, software, technology and services and ensure that its clients and vendors
                                are not subject of any sanctions. The Users will also adopt appropriate processes to prevent offering any illegal gratification in the form of bribes or kickbacks either in cash or in kind in the course of all dealings with each other and with SOLV. Each User represents, warrants and undertakes that it
                                has not and shall not offer, promise, give, encourage, solicit,  receive or otherwise engage in acts of bribery or corruption in  relation to the SOLV Services (including without limitation any  facilitation payment), or to obtain or retain business or any
                                advantage in business for any member of the other party, and has and shall ensure to the fullest extent possible that its employees and agents and others under its direction or control do not do so. The Users shall also ensure that they comply with all applicable laws while availing the SOLV Services, including
                                labour laws and they will not engage in or encourage slavery and child labour in any manner whatsoever. Any instances of such violations will be viewed in a serious manner and We reserve the right to take all appropriate actions or remedies as may be required under the circumstances
                            </p>
                        </div>
                        <div>
                            <p>
                                <li>
                                    <b>DISCLAIMER OF WARRANTIES</b>
                                </li>
                            </p>
                            <p>
                                We provide SOLV services, our content, and all content and
                                materials available through the SOLV services and website “as
                                is” and “as available” without any warranty or condition of any
                                kind, express or implied. SOLV, on behalf of itself, its
                                affiliates and its licensors, disclaims all warranties of any
                                kind, whether express or implied, relating to the SOLV services,
                                our content, or the website, including implied warranties of
                                merchantability, fitness for a particular purpose, quiet
                                enjoyment, quality, accuracy, title and non-infringement and any
                                warranty arising out of course of dealing, usage or trade.
                                Further the current version of platform may be susceptible to
                                bugs and glitches.
                            </p>
                            <p>
                                No advice or information, whether oral or written, obtained by
                                you from SOLV services, from our content, or which is otherwise
                                available through SOLV services will create any warranty
                                regarding SOLV, third party service offerings, partner services
                                or any service that is not expressly stated in these terms. You
                                will use the SOLV services and use, access, download, or
                                otherwise obtain our content, or other content available through
                                SOLV services, at your own discretion and risk. You assume all
                                risk for any damage that may result from your use of or access
                                to the SOLV services, from your dealings with any third party
                                service providers, other members or partners and from your use
                                of our content or other content available through SOLV services.
                                You acknowledge that SOLV is solely a facilitator between you
                                and third party service providers. SOLV expressly disclaims any
                                liability for any errors or omissions in our content or SOLV
                                services, including the inaccuracy or incompleteness of any such
                                user verification, credit reports, credit scores, offers or
                                other information, tools or analyses available through SOLV
                                services or our content on the website.
                            </p>
                        </div>
                        <div>
                            <p> <li>     <b>LIMITATION OF LIABILITY</b> </li> </p>
                            <p>
                                SOLV and SOLV parties will not be liable to you or any third party for any special, indirect, incidental, consequential or punitive damages (such as loss of profits, loss of goodwill, loss of use, loss of data, business interruption, or other intangible losses) arising out of or relating in any way to SOLV
                                services, our content or use of the website.
                            </p>
                            <p>
                                In no event will the total liability of the SOLV parties to you for all claims arising out of or relating to the use of, or any inability to use any portion of, SOLV services, or otherwise arising out of or relating to the agreement, whether in contract, tort, or otherwise, exceed in the aggregate GHS100,000.
                            </p>
                        </div>
                        <div>
                            <p>
                                <li>
                                    <b>INDEMNITY</b>
                                </li>
                            </p>
                            <p>
                                You will defend (if requested by any SOLV Party), indemnify, and hold harmless the SOLV Parties from and against any and all claims, losses, expenses, demands, or liabilities, including attorneys' fees and costs, incurred by the SOLV Parties in connection with any claim by a third party arising out of or in any way related to:
                                <br />
                                (1) your use of SOLV Services, our Content or the Website.
                                <br />
                                (2) your violation or alleged violation of the Agreement or your violation or alleged violation of any applicable law.
                                <br />
                                (3) your infringement or alleged infringement of any intellectual property or other right of any other person or entity.
                                <br />
                                (4) any dispute between you and a third party, including any provider of third party content or other Users. You must not settle any such claim or matter without the prior written consent of SOLV. The SOLV reserve the right, at their own
                                expense, to assume the exclusive defence and control of any matter subject to indemnification by you, and you further agree that you will cooperate fully in the defence of any such claims.
                            </p>
                        </div>
                        <div> <p>     <li>         <b>GENERAL PROVISIONS</b>     </li> </p>
                            <b>15.1 Grievances</b>
                            <p>
                                If you have any other concern or grievance with respect to any of content/information/data on the Website, please send an e-mail to our compliance team on <a href="mailto:compliance@solv.com.gh">     {" "}     compliance@solv.com.gh{" "} </a>
                                and we will study the matter and take such action as we deem appropriate under the circumstances.
                            </p>
                            <b>15.2 Assignment</b>
                            <p>
                                You cannot assign or otherwise transfer the Terms, SOLV Services or any rights hereunder to any third party. Our rights under the Terms with respect to SOLV Services are freely transferable to any third parties without the requirement of seeking your consent.
                            </p>
                            <b>15.3 Governing Law</b>
                            <p>
                                This Agreement shall be construed, interpreted and applied in accordance with, and shall be governed by, the laws applicable in the Republic of Ghana. The Ghanaian courts shall have exclusive jurisdiction to entertain any dispute or suit arising out of or in relation to this Agreement.
                            </p>
                        </div>

                        <div id="annexures">
                            <div id="annexA">
                                <h3>ANNEXURE A</h3>
                                <p>TICK THE CATEGORY APPLICABLE</p>
                                <div className="tableDiv">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col">Category</th>
                                                <th scope="col"> Nature of the Entity</th>
                                                <th scope="col">KYC Documents</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            <tr>
                                                <td>A</td>
                                                <td>
                                                    <span>Limited Liability Company</span>
                                                    <br />
                                                </td>
                                                <td>
                                                    <ul >
                                                        <li>
                                                            Certificate of Incorporation issued by the Registrar
                                                            of Companies
                                                        </li>
                                                        <li>Business Registration Documentation</li>
                                                        <li>Company Constitution</li>
                                                        <li>Copy of valid IDs of the Directors</li>
                                                        <li>TIN Numbers of all Directors</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>B</td>
                                                <td>Registered Partnership</td>
                                                <td>
                                                    <ul>
                                                        <li>Registered Partnership Agreement or Deed</li>
                                                        <li>Business Registration Documentation</li>
                                                        <li>Copy of valid IDs of Partners</li>
                                                        <li>TIN Numbers of Partners</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>C</td>
                                                <td>Sole Proprietorship</td>
                                                <td>
                                                    <ul>
                                                        <li>
                                                            Certificate of Business registration or Incorporation
                                                            issued by Registrar of Companies
                                                        </li>
                                                        <li>Business Permit</li>
                                                        <li>Copy of valid ID of the Sole Proprietor</li>
                                                        <li>GRA TIN</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="3">
                                                    <p> Note: The signing authorities should be as following for the referred entities. </p>
                                                    <p style={{marginLeft:"10px"}}> Category A – Directors or Authorized signatories as per Board Resolution.  </p> 
                                                    <p style={{marginLeft:"10px"}}> Category B - Partners  </p> 
                                                    <p style={{marginLeft:"10px"}}> Category C - Sole Proprietor</p>
                                                    <p> Where the Authorized Representative is a specific individual authorized to receive such information on behalf of such an entity, Authority Letter and KYC of the specific individual will also need to be affixed (ID Proof, Address Proof and ID Card). </p>
                                                    <p> The above list is not exhaustive, and SOLV has the right to request for additional documentation and/or information when deemed necessary. </p>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div id="annexB">
                                <h3 className="alignment"><b>ANNEXURE B</b>{" "}</h3>
                                <p className="alignment">BILS AND OTHER FINANCIAL SERVICES</p>
                                <div className="tableDiv">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col">Nature of the Entity</th>
                                                <th scope="col">Documents</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Limited Liability Company</td>
                                                <td>
                                                    <ul>
                                                        <li>
                                                            Business Registration No. or Certificate of
                                                            Incorporation
                                                        </li>
                                                        <li>GRA TIN</li>
                                                        <li>Bank statement</li>
                                                        <li>Shops and establishment letter</li>
                                                        <li>Proof of address</li>
                                                        <li>
                                                            Invoice and related transaction data including
                                                            logistics information
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Limited Liability Company</td>
                                                <td>
                                                    <ul>
                                                        <li>
                                                            Business Registration No. or Certificate of
                                                            Incorporation
                                                        </li>
                                                        <li>GRA TIN</li>
                                                        <li>Bank statement</li>
                                                        <li>Shops and establishment letter</li>
                                                        <li>Proof of address</li>
                                                        <li>
                                                            Invoice and related transaction data including
                                                            logistics information
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Sole Proprietorship</td>
                                                <td>
                                                    <ul>
                                                        <li> Copy of valid ID of the owner</li>
                                                        <li> Bank statement </li>
                                                        <li> Shops and establishment letter </li>
                                                        <li> Proof of address</li>
                                                        <li> Invoice and related transaction data including logistics information</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div id="annexC">
                                <h3 className="alignment"> <b>ANNEXURE C</b> </h3>
                                <p className="alignment">SCF DOCUMENTS</p>
                                <div className="tableDiv">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col">Nature of the Entity</th>
                                                <th scope="col">Documents</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Dealer/Anchor</td>
                                                <td>
                                                    <ul>
                                                        <li>Proof of Operational address (i.e. Business Permit)</li>
                                                        <li>Business Registration No. or Certificate of Incorporation</li>
                                                        <li>Utility Bill, if required</li>
                                                        <li>Bank statements for the last 6-12 months</li>
                                                    </ul>
                                                    <br />
                                                    <span>From the owner:</span>
                                                    <br />
                                                    <ul>
                                                        <li style={{marginLeft:"10px"}}>A certified copy of ID or passport</li>
                                                        <li style={{marginLeft:"10px"}}>One recent photograph</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </ol>
                </div>
            </div>
        </div>
        </>
    );
}

export default TermsOfService;
