import React from 'react'
import {Link} from 'react-router-dom'
import Nav from '../components/Nav'
import Foot from '../components/Foot'
import TopRight from '../assets/about/top-right.svg'
import LeftShape from '../assets/about/left-shap.svg'
import RightShape from '../assets/about/right-shape.svg'
import MiddleBanner from '../assets/about/middle-banner.png'
import Dan from '../assets/about/staff/Dan.svg'
import Jiten from '../assets/about/staff/Jiten.svg'
import Mansa from '../assets/about/staff/Mansa.svg'
import Rita from '../assets/about/staff/Rita.svg'
import Joel from '../assets/about/staff/Joel.svg'
import Sam from '../assets/about/staff/Sam.svg'
import Mike from '../assets/about/staff/Mike.svg'
import {ScrollToTop} from "../core/ScrollToTop";

function About() {
  return (
    <div>
       <Nav />
        <ScrollToTop />
        <img loading='lazy' id="top-right" src={TopRight} alt="image1" />
       <section className="one">
        <div className="section-1-wrap-extra">
          <span className="title">Our Vision</span>
          <span className="details">
          Building a digital business to business (B2B) Marketplace to support growth of Ghanaian micro, small, and medium enterprises (MSMEs). Our <Link to={"https://scventures.io/"} style={{color: "blue"}}>SC Ventures</Link> funded Marketplace is facilitating MSME growth in a trusted environment, by democratizing and easing access to Financing and Business Support solutions.
            <br /><br />Operating out of its 87 Independence Avenue office (9th Floor), Accra; SOLV collaborates with multiple Financial Institutions (FIs) and Service providers to deliver growth simplified.
           </span>
        </div>

    </section>

    <section className='two-'>
        <span className="title">Our Values</span>
        <span className="details">
        We at SOLV believe that strong core values are the foundation of an inspirational business model. Our people and customers are at the centre of everything we do. In our endeavour to ensure that we prioritize them, our values act as our beacon. They give focus and direction to the team (SOLVers), helping them work toward the larger common goal of building a digital B2B Marketplace to support the growth of Ghanaian MSMEs. Our values are interwoven throughout the cultural tapestry of SOLV and constantly evolves with the contributions each person makes. We believe that culture is a melting pot of personal beliefs and values that each SOLVer brings to the family. Each one of us holds these values paramount and live by them, every day - defining the way of life for SOLVers Our values are the guiding principles for acceptable behaviour at work. Internalizing these standards has resulted in a powerful team of SOLVers, immensely dedicated to simplifying the growth of our clients.
        </span>
    </section>

    <section className='three-extra'>
        <img loading='lazy' id="first-left" src={LeftShape} alt="first-left" />
        <img loading='lazy' id="first-right" src={RightShape} alt="first-right" />
        <div className="section-3-wrap-extra">
            <div className="first-col">
                <div className="col-info">
                    <span className="digits">1</span>
                    <div className="description">
                        <hr />
                        <span>Customer First</span>
                    </div>
                </div>
                <div className="col-info">
                    <span className="digits">2</span>
                    <div className="description">
                        <hr />
                        <span>Keep It Real</span>
                    </div>
                </div>
                <div className="col-info">
                    <span className="digits">3</span>
                    <div className="description">
                        <hr />
                        <span>Execution Focused</span>
                    </div>
                </div>
                <div className="col-info">
                    <span className="digits">4</span>
                    <div className="description">
                        <hr />
                        <span>Have Fun</span>
                    </div>
                </div>
                <div className="col-info">
                    <span className="digits">5</span>
                    <div className="description">
                        <hr />
                        <span>Value Creation</span>
                    </div>
                </div>
            </div>
            <div className="first-col">
                <div className="col-info">
                    <span className="digits">6</span>
                    <div className="description">
                        <hr />
                        <span>Think Big, Move Fast, Start Small</span>
                    </div>
                </div>
                <div className="col-info">
                    <span className="digits">7</span>
                    <div className="description">
                        <hr />
                        <span>We Win or Lose Together</span>
                    </div>
                </div>
                <div className="col-info">
                    <span className="digits">8</span>
                    <div className="description">
                        <hr />
                        <span>Be Frugal</span>
                    </div>
                </div>
                <div className="col-info">
                    <span className="digits">9</span>
                    <div className="description">
                        <hr />
                        <span>Respect, Humility, Integrity</span>
                    </div>
                </div>
                <div className="col-info">
                    <span className="digits">10</span>
                    <div className="description">
                        <hr />
                        <span>Emotional Intelligence</span>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className='four-extra'>
    <span className="title">The SOLV Marketplace</span>
        <span className="details">
            SOLV’s digital B2B Marketplace offers Ghanaian MSMEs a trusted, open, and inclusive access to financing and business support solutions that span the key trade value chains of our economy. 
            The Platform facilitates connections and negotiations with verified sellers and buyers, timely and easy access to financing, as well as simplified business support services. These are critical elements that help expand market access, deliver operational efficiencies, and sustainably promote the growth of millions of small businesses. 
            SOLV’s intuitive and user-friendly commerce platform brings the power of technologies like blockchain, artificial intelligence, and machine learning – to small businesses, helping them conduct their businesses with multiple stakeholders in a trusted environment.
        </span>
    </section>

    <img loading='lazy' className="middle-banner" src={MiddleBanner} alt="middle-banner" />

    <section className='five-extra'>
    <span className="title">Board Members</span>
        <div className="image-about">
            <div className="rols">
                <div className="items">
                    <img loading='lazy' src={Mansa} alt="Mansa" />
                    <h2>Mansa Nettey</h2>
                    <span>CEO - SC Bank PLC, Ghana</span>
                </div>
                <div className="items">
                    <img loading='lazy' src={Jiten} alt="Jiten" />
                    <h2>Jiten Arora</h2>
                    <span>Member - SC Ventures</span>
                </div>
                <div className="items">
                    <img loading='lazy' src={Dan} alt="Dan" />
                    <h2>Dan Karuga</h2>
                    <span>Member - SC Ventures (Head Africa)</span>
                </div>
            </div>
        </div>
        <span className="title">Management</span>
        <div className="image-about-x">
            <div className="rols">
                <div className="items">
                    <img loading='lazy' src={Sam} alt="Dan" />
                    <h2>Sam Kwaku Peprah</h2>
                    <span>Chief Executive Officer & Founder</span>
                </div>
            </div>
        </div>
        <div className="image-about">
            <div className="rols">
                <div className="items">
                    <img loading='lazy' src={Joel} alt="Joel" />
                    <h2>Joel Kwame Ofosu</h2>
                    <span>Chief Technology Officer</span>
                </div>
                <div className="items">
                    <img loading='lazy' src={Rita} alt="Rita" />
                    <h2>Rita Effah Adane</h2>
                    <span>Chief Risk & Compliance Officer</span>
                </div>
                <div className="items">
                    <img loading='lazy' src={Mike} alt="Mike" />
                    <h2>Michael Kweku Assem</h2>
                    <span>Chief Commercial Officer</span>
                </div>
            </div>
        </div>
    </section>

    <section className='six'>
      <div className='left'>
        <h4>SOLV in the news</h4>
        <Link to="/news"><i class="fa-solid fa-circle-arrow-right"></i></Link>
      </div>
      <span className='vl'></span>
      <div className='right'>
        <h4>Join our growing team</h4>
        <Link to="/careers"><i class="fa-solid fa-circle-arrow-right"></i></Link>
      </div>
      
    </section>
       <Foot />
    </div>
  )
}

export default About