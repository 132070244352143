import React from 'react'
import Nav from '../components/Nav'
import Foot from '../components/Foot'
import FirstRight from '../assets/first-right.svg'
import ScreenImg from '../assets/screen.svg'
import LeftCirlce from '../assets/left-circle.svg'
import RightCircle from '../assets/right-circle.svg'
import LeftShapeMobile1 from '../assets/left-shape-mobile-1.svg'
import Icon1 from '../assets/icon-1.svg'
import Icon2 from '../assets/icon-2.svg'
import Icon3 from '../assets/icon-3.svg'
import Icon4 from '../assets/icon-4.svg'
import Icon5 from '../assets/icon-5.svg'
import Icon6 from '../assets/icon-6.svg'
import SecondRight from '../assets/secon-right.svg'
import CircledLogo from '../assets/circled-logo.svg'

import {ScrollToTop} from "../core/ScrollToTop";
import {Link} from "react-router-dom";
import CustomerCardSlider from "../components/swiper/CustomerCardSlider";

function Home() {
  return (
    <div>
      <Nav />
        <ScrollToTop />
        {/*<TopRightAnimate />*/}
        <img loading='lazy' id="top-right" src={FirstRight} alt="image1" />
        <section className='one'>
          <div className="section-1-wrap">
            <span className="title">A growth platform for small business</span>
            <span className="fs">Finance . Services</span>
            <span className="details">
                SOLV is a B2B marketplace for SMEs. The SOLV platform facilitates commerce in a trusted environment, while easing access to finance and business services through one seamless digital experience.
            </span>
            <div className="btns">
                <Link to="/" className="get-started">Get Started <i className="fa fa-arrow-right"></i></Link>
                <Link to="/about" className="know-more">Know More</Link>
            </div>
            <span className="other-details" style={{color: "#0F4C8A"}}>
                All that your business needs to grow - in one marketplace
            </span>
          </div>
        </section>

        <section className="two">
          <img loading='lazy' id="left-cirle" src={LeftCirlce} alt="left-circle" />
          <div className="section-2-wrap">
          <span className="stats">30+ Anchors || 1,200+ Dealers</span>
          <span className="title">B2B digital marketplace</span>
          <span className="details">
              MSMEs that lack reasonable financial status and documentation face numerous challenges in terms of financial support thereby stifling their growth. Growing your business becomes easy once onboarded on the SOLV B2B online marketplace. MSMEs are offered seamless and quick access to collateral-free preapproved credit limits. Value-added services such as audited financial accounts, book keeping, legal advisory, insurance and competitive pricing makes doing business on SOLV quick and hassle-free.
          </span>
          <img loading='lazy' id="screen" src={ScreenImg} alt="screen"/>
          </div>
          <img id="right-circle" src={RightCircle} alt="right-circle" />
        </section>

        <section className='three'>
        <img loading='lazy' id="second-left" src={LeftShapeMobile1} alt={"mobile-p"}/>
        <div className="section-3-wrap">
            <div className="top-details">
                <span className="service">Service</span>
                <span className="why">Why Choose SOLV</span>
                <span className="why-info">We are building a digital B2B Marketplace to support growth of Ghanaian MSMEs.</span>
            </div>
            <div className="sub-details">
                <div className="left-sub">
                    <div className="items-wrapper">
                        <span className="icon"><img loading='lazy' src={Icon1} alt="icon-1" /></span>
                        <div className="bg-t">
                            <span className="txt-bg">1</span>
                            <span className="info">Pure marketplace model connecting buyers & sellers for an open and transparent business environment</span>
                        </div>
                    </div>
                    <div className="items-wrapper">
                        <span className="icon"><img loading='lazy' src={Icon2} alt="icon-2" /></span>
                        <div className="bg-t">
                            <span className="txt-bg">2</span>
                            <span className="info">One-stop shop offering value added services inclusive of financial and business services</span>
                        </div>
                    </div>
                    <div className="items-wrapper">
                        <span className="icon"><img loading='lazy' src={Icon3} alt="icon-3" /></span>
                        <div className="bg-t">
                            <span className="txt-bg">3</span>
                            <span className="info">SOLV profiling creates a trust-based ecosystem & will serve as a reliable lead for FIs</span>
                        </div>
                    </div>
                </div>
                    <div className="left-sub">
                        <div className="items-wrapper">
                            <span className="icon"><img loading='lazy' src={Icon4} alt="icon-4" /></span>
                            <div className="bg-t">
                                <span className="txt-bg">4</span>
                                <span className="info">Superior customer experience across all touchpoints including funding, payments & support</span>
                            </div>
                        </div>
                        <div className="items-wrapper">
                            <span className="icon"><img loading='lazy' src={Icon5} alt="icon-5" /></span>
                            <div className="bg-t">
                                <span className="txt-bg">5</span>
                                <span className="info">AI and ML enabled platform for data-driven decisioning and end-to-end transaction enablement</span>
                            </div>
                        </div>
                        <div className="items-wrapper">
                            <span className="icon"><img loading='lazy' src={Icon6} alt="icon-6" /></span>
                            <div className="bg-t">
                                <span className="txt-bg">6</span>
                                <span className="info">SOLV ensures fair business practices and tighter controls on data security and privacy</span>
                            </div>
                        </div>
            </div>
        </div>
        </div>
        </section>

        <section className='four'>
        <img loading='lazy' id="second-right" src={SecondRight} alt="second-right" />
        <div className='section-4-wrap'>
        <span className="title">System features</span>
        <span className="details">The SOLV platform provides small businesses with various financing options to support their growth. We are a financial services marketplace backed by technology and a network comprising several banks and non banks.</span>
        <div className="main-groupings-4">
        <div className="sub-main-groupings-4">
            <div className="sub-groupings-4">
                <span className="title-4">Master Anchor</span>
                <span className="info">Master Anchors are responsible for the production and supply of goods to the MSME sector. They often produce goods in significant quantities and may have multiple product lines. MSMEs rely on them as their primary source for obtaining the necessary products.</span>
            </div>
            <div className="sub-groupings-4">
                <span className="title-4">Anchor</span>
                <span className="info">Anchors play a crucial role in the supply chain by ensuring that products from Master Anchors reach the MSMEs efficiently. They may offer services such as bulk purchasing, logistics, and localized distribution to cater to the needs of MSMEs.</span>
            </div>
        </div>
        <div className="sub-main-groupings-4">
            <div className="sub-groupings-4">
                <span className="title-4">Dealer</span>
                <span className="info">Dealers operate at the retail level and interact directly with end customers. They purchase products from Anchors or other distributors and provide them to customers in smaller quantities. Their role is to make products easily accessible to consumers, including MSMEs, in a convenient and localized manner.</span>
            </div>
            <div className="sub-groupings-4">
                <span className="title-4">Programs</span>
                <span className="info">A program represents a formal agreement or set of terms and conditions established between SOLV and financial institutions. These terms outline the specific parameters, criteria, and terms for lending funds by the financial institutions, who, in turn, make these funds available to dealers.</span>
            </div>
        </div>
      </div>
      </div>
    </section>

    <section className='five'>
    <div className="sub-groupings-5">
            <div className="groupings-5">
                <span className="title">Hear from our customers</span>
                <span className="details">SOLV has helped numerous small business owners across Ghana
                    achieve growth and realise their true potential.</span>
            </div>
            <img loading='lazy' className="logo-circle"  style={{paddingLeft: "10px"}}  src={CircledLogo} alt="logo-circle" />
        </div>
        <CustomerCardSlider />
    </section>
      <Foot />
    </div>
  )
}

export default Home