import React from 'react'
import Nav from '../components/Nav'
import Foot from '../components/Foot'
import TopRight from '../assets/business/top-right.svg'
import { Link } from 'react-router-dom'
import LeftShape from '../assets/about/left-shap.svg'
import RightShape from '../assets/about/right-shape.svg'
import GhMap from '../assets/business/gh-map.svg'
import SupportImg from '../assets/business/support-img.svg'
import {ScrollToTop} from "../core/ScrollToTop";

function Business() {
  return (
    <div>
      <Nav />
        <ScrollToTop />
        <img loading='lazy' id="top-right" src={TopRight} alt='top-right' />
        <section className='one'>
            <div className='section-1-wrap-extra'>
              <span className="title">Affordable, Efficient, and Reliable Business Support Solutions</span>
              <span className="details">
              Balancing day-to-day operations with the need to foster business growth can be challenging. 
              <br />At SOLV we remain dedicated to revolutionizing how MSMEs conduct their operations in an efficient and sustainable manner, leveraging our extensive network of individual and institutional service providers.
              </span>
              <div className="btns">
                  <Link to="/contact-us" className="get-started">Contact Us <i className="fa fa-arrow-right"></i></Link>
              </div>
          </div>
        </section>
        <section className="two">
          <img loading='lazy' id="first-left" src={LeftShape} alt="first-left" />
          <img loading='lazy' id="first-right-extra" src={RightShape} alt="first-right" />
          <div className="section-2-wrap-x">
          <div className="col-description">
            <span className="title">Operational Solutions</span>
                <span className="details">
                    <p className='fs'>Offering business support services</p>
                    MSMEs significantly contribute to the Ghanaian economy, accounting for over 2.5 million businesses, 60% of the formal labor force, and 22% of GDP. 
                    <br /><br />Leveraging a digital adoption rate greater than 53%, we at SOLV are committed to delivering business support services via our digital Marketplace to nurture and propel the sustained development and growth of MSMEs. These services provide our Marketplace participants with the right level of custom-made resources for capacity-building and operational efficiency to boost their competitiveness and make them better candidates for prompt and efficiently priced financing.
                    <br /><br />Our range of affordable and accessible Business Support services contribute to uplifting the profile of MSMEs, fostering business networking, facilitating market expansion, and ultimately shaping our Marketplace participants to fit into the regulatory compliance standards that support their integration into cross-border and continental Trade Corridors.
                    </span>
            <div className="checks-wrapper-">
              <div className="checks-wrapper">
                  <div className="bus-check">
                    <i className="fa fa-check"></i><span>Marketplace with multiple service providers</span>
                  </div>
                  <div className="bus-check">
                    <i className="fa fa-check"></i><span>Access to reliable & affordable business services</span>
                  </div>
                  <div className="bus-check">
                    <i className="fa fa-check"></i><span>Enabling operational efficiency</span>
                  </div>
              </div>
            </div>
          </div>
        <div className="col-checks">
            <img loading='lazy' className="gh-map" src={GhMap} alt="gh-map" />
        </div>
        </div>
    </section>

      <section className='two-extra'>
        <div className='section-2-wrap'>
        <span className="title">Our Support Services</span>
          <span className="extra-details">
          SOLV's suite of Business Support services are specifically tailored to address the most crucial business requirements promptly and at a cost that’s predictable and affordable.
          </span>
          </div>
          <span className="extra-details">
              <img loading='lazy' src={SupportImg} alt="support-img" />
        </span>
      </section>
      <Foot />
    </div>
  )
}

export default Business